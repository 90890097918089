import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
} from "@mui/material";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { getCreditDataRef, useAuth0Token } from "../common/http-requests";
import { creditRequestType, creditDataRefResponseType } from "../common/type";
import { Store } from "../store";

// MEMO: 鳴門パースペクティブの各観点毎の取得成績値である ps_credits の要素数は常に26個。
const DefaultCreditDataRef: creditDataRefResponseType = {
  data_ref: [],
};

const PortfolioDataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);

  // データ例
  //   "data_ref": [
  //     { 'earned_period': 2019, 'earned_semester_code': '1', 'subject_name': '日本国憲法', 'credit_score': 100, 'ps_credits': [1.0, ..., 0.0] },
  //     ...
  //     ]
  const [creditDataRefData, setCreditDataRefData] = useState<creditDataRefResponseType>(DefaultCreditDataRef);

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getCreditData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getCreditData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: creditRequestType = {
        login_user_id,
      };
      const data: creditDataRefResponseType = await getCreditDataRef(
        await getToken(),
        requestParams
      );
      setCreditDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            ※実装中：単位画面
            {/* 鈴木さん、実装をお願いします。 */}
          </>
        )}
      </Container>
    </>
  );
};

export default PortfolioDataRefPage;
