import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Box, styled } from "@mui/material";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FoundationIcon from '@mui/icons-material/Foundation';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { stringify } from "querystring";

const PageUrl = [ // 0～2は全画面共通
  { page: "/home", label: "ホーム"},
  { page: "/-replace-DataRef", label: "データ参照"},
  { page: process.env.REACT_APP_NARUTO_LCU_URL ?? "", label: "LCU"},
  { page: "/home", label: "eポートフォリオシステム"}, // portfolio画面のみ
  { page: "/home", label: "発行"}, // DS画面のみ
]

export const VisualizedMenu = (props: any) => {
  const { targetPage, menuLabel, targetTab, currentPath } = props;
  const { isDesktop, isMobile } = useCustomMediaQuery();
  
  const DEFAULT_COLOR = "white";

  // マウスホバー時のスタイル
  const linkHoverStyle = {
    backgroundColor: '#226DAE',
    opacity: '0.8',
  };
  // デフォルト時のスタイル
  const linkDefaultStyle = {
    backgroundColor: 'transparent',
    opacity: '1.0',
  };
  // デフォルト時のスタイル(モバイル)
  const linkDefaultMobilStyle = {
    backgroundColor: 'transparent',
    opacity: '1.0',
  };

  //現在開いている画面はlinkHoverStyleを適用する
  const leaveDesktopStyle = currentPath === targetPage ? linkHoverStyle : linkDefaultStyle;
  const leaveMobileStyle = currentPath === targetPage ? linkHoverStyle : linkDefaultMobilStyle;

  const DesktopMenu = styled(Link)(() => ({
    flex: 1, 
    display: 'flex', 
    justifyContent: 'center', 
    padding: "15px 0", 
    transition: 'color 0.3s',
    color: DEFAULT_COLOR,
    ...(currentPath === targetPage ? linkHoverStyle : {})
  }));

  const MobileMenu = styled(Link)(() => ({
    flex: 1, 
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center', 
    alignItems: 'center',
    padding: '5px 0',
    margin: '0',
    fontSize: '.8rem',
    transition: 'color 0.3s', 
    color: DEFAULT_COLOR,
    ...(currentPath === targetPage ? linkHoverStyle : linkDefaultMobilStyle)
  }));

  return (
    <>
     {isDesktop && (
      <DesktopMenu
        to={targetPage}
        target={targetTab || ""}
        onMouseEnter={(event) => { Object.assign(event.currentTarget.style, linkHoverStyle); }}
        onMouseLeave={(event) => { Object.assign(event.currentTarget.style, leaveDesktopStyle); }}>
        {props.children}
        <div style={{ marginLeft: "0.2em" }}>{menuLabel}</div>
      </DesktopMenu>
     )}
     {isMobile && (
      <MobileMenu
        to={targetPage}
        target={targetTab || ""}
        onMouseEnter={(event) => { Object.assign(event.currentTarget.style, linkHoverStyle); }}
        onMouseLeave={(event) => { Object.assign(event.currentTarget.style, leaveMobileStyle); }}>
        {props.children}
        <div style={{ textAlign: "center" }}>{menuLabel}</div>
      </MobileMenu>
     )}
    </>
  );
};

export const Toolbar = (props: any) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [updatedPageUrl, setUpdatedPageUrl] = useState(PageUrl);

  const Toolbar = styled(Box)(() => ({
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    backgroundColor: '#d9d9d9',
    zIndex: '100',
  }));

  const VisualizedMenuNone = styled(Box)(() => ({
    flex: 1, 
    display: 'flex', 
    justifyContent: 'center', 
    padding: "15px 0", 
    transition: 'color 0.3s',
  }));

  const replace = () => {
    const pathname = window.location.pathname;
    const segments = pathname.split('/');
    const lastSegment = segments[segments.length - 1]; // 最後のセグメントを取得
    const newPageUrl = PageUrl.map(item => {
      if (item.page === "/-replace-DataRef") {
        return { ...item, page: item.page.replace("-replace-", lastSegment.replace("DataRef", "")) };
      }
      return item;
    });
    setUpdatedPageUrl(newPageUrl);
  };
  
  useEffect(() => {
    setCurrentPath(location.pathname);
    replace();
  }, [location]);
  
  return (
    <>
    <Box sx={{ marginTop: '60px' }}/>
    <Toolbar>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <VisualizedMenu targetPage={updatedPageUrl[0].page} menuLabel={updatedPageUrl[0].label}>
          <HomeIcon />
        </VisualizedMenu>
        <VisualizedMenu targetPage={updatedPageUrl[1].page} menuLabel={updatedPageUrl[1].label} currentPath={currentPath}>
          <AccountTreeIcon />
        </VisualizedMenu>
        { currentPath === "/portfolio" ? (
        <VisualizedMenu targetPage={updatedPageUrl[3].page} menuLabel={updatedPageUrl[3].label}>
          <FolderSharedIcon />
        </VisualizedMenu>
        ) : currentPath === "/diplomaSupplement" ? (
        <VisualizedMenu targetPage={updatedPageUrl[4].page} menuLabel={updatedPageUrl[4].label}>
          <PictureAsPdfIcon />
        </VisualizedMenu>
        ) : (
        <VisualizedMenuNone />
        )}
        <VisualizedMenu targetPage={updatedPageUrl[2].page} menuLabel={updatedPageUrl[2].label} targetTab="_blank">
          <FoundationIcon />
        </VisualizedMenu>
      </Box >
    </Toolbar>
    </>
  );
};
