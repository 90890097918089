import React, { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { getCreditDataRef, useAuth0Token } from "../common/http-requests";
import { creditRequestType, creditDataRefResponseType } from "../common/type";
import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  tableHeadStyle : {
    textAlign: "center",
    borderRight: "solid 1px #fff",
    lineHeight: "1rem",
    padding: "10px 2px",
  },
  tableCellStyle: {
    position: 'relative',
    padding: "0 2px",
    textAlign: 'center',
    overflow: "hidden",
  },
  tableCellRefStyle: {
    border: 'solid 1px #e2e2e2',
    boxSizing: 'border-box',
  },
  tableCellPsStyle: {
    border: 'solid 1px #bbcddd',
    boxSizing: 'border-box',
  },
  tableCellVertical: {
    writingMode: 'vertical-rl', 
    textOrientation: 'upright',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    width: '100%',
    height: '22%',
  },
});

const BAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7",
];

// MEMO: 鳴門パースペクティブの各観点毎の取得成績値である ps_credits の要素数は常に26個。
const DefaultCreditDataRef: creditDataRefResponseType = {
  data_ref: [],
};

const CreditDataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();

  // データ例
  //   "data_ref": [
  //     { 'earned_period': 2019, 'earned_semester_code': '1', 'subject_name': '日本国憲法', 'credit_score': 100, 'ps_credits': [1.0, ..., 0.0] },
  //     ...
  //     ]
  const [creditDataRefData, setCreditDataRefData] = useState<creditDataRefResponseType>(DefaultCreditDataRef);

  const perspectiveList = [
    { id: "01", label: "倫理観・使命感・人権意識" },
    { id: "02", label: "子供観" },
    { id: "03", label: "学習観" },
    { id: "04", label: "領域・教科の専門的知識" },
    { id: "05", label: "教職の専門的知識" },
    { id: "06", label: "現代社会の諸課題に関する知識" },
    { id: "07", label: "子供理解力" },
    { id: "08", label: "個人的指導力（ファシリテート力）" },
    { id: "09", label: "集団指導力（学級経営力）" },
    { id: "10", label: "教育データの利活用能力" },
    { id: "11", label: "学習指導及び生徒指導における構想力・展開力・評価力" },
    { id: "12", label: "特別な配慮や支援を必要とする子供への対応力" },
    { id: "13", label: "主体性・自律性" },
    { id: "14", label: "レジリエンス" },
    { id: "15", label: "自己肯定感" },
    { id: "16", label: "ダイバーシティ・インクルージョン" },
    { id: "17", label: "コミュニケーション力" },
    { id: "18", label: "チームワーキング力" },
    { id: "19", label: "合意形成力" },
    { id: "20", label: "見出した課題に対する多面的・多角的な見方・考え方" },
    { id: "21", label: "データの分析・活用力" },
    { id: "22", label: "論理的な思考力・表現力" },
    { id: "23", label: "価値創造力" },
    { id: "24", label: "ICT活用力" },
    { id: "25", label: "自己調整力" },
    { id: "26", label: "学び続ける態度" },
  ];
  const pl = perspectiveList;
  const tableHeadListCS = [
    { label: "修得年", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "修得期", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "授業科目", width: "20vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "単位", width: "3vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "得点", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  ]
  const tableHeadListPers = [
    { label: "教師としての構え", width: "10vw", color: "white", colSpan: 3, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[0], pl[1], pl[2]] },
    { label: "教師として必要な専門的知識", width: "10vw", color: "white", colSpan: 3, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[3], pl[4], pl[5]] },
    { label: "教師として必要な基本的技能", width: "10vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[6], pl[7], pl[8], pl[9]] },
    { label: "教師として必要な実践的指導力", width: "10vw", color: "white", colSpan: 2, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[10], pl[11]] },
    { label: "人間性", width: "10vw", color: "white", colSpan: 4, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[12], pl[13], pl[14], pl[15]] },
    { label: "連携・協働力", width: "10vw", color: "white", colSpan: 3, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[16], pl[17], pl[18]] },
    { label: "課題発見・価値創造力", width: "10vw", color: "white", colSpan: 5, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[19], pl[20], pl[21], pl[22], pl[23]] },
    { label: "省察力と職能成長を志向する態度", width: "10vw", color: "white", colSpan: 2, backgroundColor: BAR_CHART_COLORS[1], perspective: [pl[24], pl[25]] },
  ]

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getCreditData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getCreditData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: creditRequestType = {
        login_user_id,
      };
      const data: creditDataRefResponseType = await getCreditDataRef(
        await getToken(),
        requestParams
      );
      setCreditDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const earnedSemesterCodeFormat = (props: string) => {
    const semester = props === "1" ? "前期" : props === "2" ? "後期" : "-";
    return semester;
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead key="table-head">
                    <TableRow>
                      <TableCell 
                        className={classes.tableHeadStyle} 
                        sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[4] }}
                        colSpan={5}
                      >授業科目</TableCell>
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                      <TableCell 
                        className={classes.tableHeadStyle} 
                        sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[1] }}
                        colSpan={26}
                      >鳴門パースペクティブ</TableCell>
                    </TableRow>
                    <TableRow>
                      {tableHeadListCS.map((item: any, index: number) => (
                        <TableCell 
                          key={`${item.label}-${index}`}
                          className={classes.tableHeadStyle} 
                          sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                          rowSpan={3}
                        >{item.label}
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                      {tableHeadListPers.map((item: any, index: number) => (
                        <TableCell 
                          key={`${item.label}-${index}`}
                          className={classes.tableHeadStyle} 
                          sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor, fontSize: '.8rem' }}
                          colSpan={item.colSpan}
                        >{item.label}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                      {tableHeadListPers.map((pers: any, persindex: number) => (
                        pers.perspective.map((item: any, index: number) => (
                          <TableCell 
                            key={`${item.label}-${index}`}
                            className={classes.tableHeadStyle} 
                            sx={{ width: '1vw', color: pers.color, backgroundColor: pers.backgroundColor }}
                          >{item.id}
                          </TableCell>
                        ))
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                    {tableHeadListPers.map((pers: any, persindex: number) => (
                        pers.perspective.map((item: any, index: number) => (
                          <TableCell 
                            key={`${item.label}-${index}`}
                            className={classes.tableHeadStyle} 
                            sx={{ width: '1vw', color: pers.color, backgroundColor: pers.backgroundColor, fontSize: '.8rem'  }}
                          ><div className={classes.tableCellVertical}>{item.label}</div>
                          </TableCell>
                        ))
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key="table-body">
                    {creditDataRefData.data_ref.map((item: any, index: number) =>(
                      <TableRow key={`table-body-row-${index}`}>
                        <React.Fragment key={`${item.subject_name}-${index}`}>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.earned_period}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{earnedSemesterCodeFormat(item.earned_semester_code)}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.subject_name}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit_score}</TableCell>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          {item.ps_credits.map((ps: any, psindex: number) => (
                            <TableCell 
                              key={`ps-${psindex}`}
                              className={`${classes.tableCellStyle} ${classes.tableCellPsStyle}`} 
                              sx={{ color: BAR_CHART_COLORS[3] }}
                              >{ps}</TableCell>
                          ))}
                        </React.Fragment>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default CreditDataRefPage;
