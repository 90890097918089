import { useState, useEffect, useContext } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getUser, useAuth0Token } from "./common/http-requests";
import { userResponseType } from "./common/type";
import Appbar from "./components/appbar";
import Sidebar from "./components/sidebar";
import HomePage from "./pages/HomePage";
import AccountPage from "./pages/AccountPage";
import DiplomaSupplementPage from "./pages/DiplomaSupplementPage";
import CreditPage from "./pages/CreditPage";
import TrainingIndicatorPage from "./pages/TrainingIndicatorPage";
import GPAPage from "./pages/GPAPage";
import PortfolioPage from "./pages/PortfolioPage";
import NCBTPage from "./pages/NCBTPage";
import SETSPage from "./pages/SETSPage";
import ChartbotPage from "./pages/ChartbotPage";
import CreditDataRefPage from "./pages/CreditDataRefPage";
import TrainingIndicatorDataRefPage from "./pages/TrainingIndicatorDataRefPage";
import GPADataRefPage from "./pages/GPADataRefPage";
import PortfolioDataRefPage from "./pages/PortfolioDataRefPage";
import NCBTDataRefPage from "./pages/NCBTDataRefPage";
import SETSDataRefPage from "./pages/SETSDataRefPage";
import types from "./store/types";
import { Store } from "./store";

export const RouterComponent = () => {
  const { getToken } = useAuth0Token();
  const [isSending, setIsSending] = useState(true);

  const { user } = useAuth0();

  const userEmail = user?.email;

  const { state, dispatch } = useContext(Store);

  useEffect(() => {
    getUserData(userEmail);
  }, []);

  const getUserData = async (email?: string) => {
    setIsSending(true);
    try {
      const requestParams: {
        email?: string;
      } = {
        email,
      };
      const data: userResponseType = await getUser(
        await getToken(),
        requestParams
      );
      dispatch({
        type: types.SET_USER_INFO,
        payload: {
          userId: data.id,
          userEmail: data.email,
          userName: data.user_name,
          userType: data.user_type,
          userStudentNumber: data.student_number,
        },
      });
    } catch {
    } finally {
      setIsSending(false);
    }
  };
  return isSending ? (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className="text-center">
            <CircularProgress
              style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
              size="3rem"
            />
          </div>
          <Typography>ユーザ情報を取得中...</Typography>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Appbar />
      <Sidebar />
      <Routes>
        {/**学生一覧画面からダッシュボード画面に遷移した後、サイドバーから「ダッシュボード」に遷移した場合に
         * stateがないとホワイトアウトしてしまうためnullを指定する
         */}
        <Route path="/" element={<Navigate to="/home" state={{}} />} />
        <Route path="/home" element={<HomePage />} />

        <Route path="/account" element={<AccountPage />} />
        {/* <Route path="/studentRegistration" element={<StudentRegistrationPage />} /> */}
        {/* <Route path="/studentAchievements" element={<studentAchievementsPage />} /> */}
        {/* <Route path="/language" element={<LanguagePage />} /> */}
        {/* <Route path="/links" element={<LinksPage />} /> */}
        {/* <Route path="/point" element={<PointPage />} /> */}
        {/* <Route path="/help" element={<HelpPage />} /> */}

        <Route path="/diplomaSupplement" element={<DiplomaSupplementPage />} />
        <Route path="/credit" element={<CreditPage />} />
        <Route path="/trainingIndicator" element={<TrainingIndicatorPage />} />
        <Route path="/gpa" element={<GPAPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/ncbt" element={<NCBTPage />} />
        <Route path="/sets" element={<SETSPage />} />
        <Route path="/chatbot" element={<ChartbotPage />} />

        <Route path="/creditDataRef" element={<CreditDataRefPage />} />
        <Route path="/trainingIndicatorDataRef" element={<TrainingIndicatorDataRefPage />} />
        <Route path="/gpaDataRef" element={<GPADataRefPage />} />
        <Route path="/portfolioDataRef" element={<PortfolioDataRefPage />} />
        <Route path="/ncbtDataRef" element={<NCBTDataRefPage />} />
        <Route path="/setsDataRef" element={<SETSDataRefPage />} />
      </Routes>
    </>
  );
};
