import { makeStyles } from '@material-ui/core/styles';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts';

interface CreditRadarChartTypeProps {
    data: any;
    raderChartSize: number[];
    raderChartColors: string[];
};

interface RenderTypeProps {
    x: number;
    y: number;
    textAnchor: string;
    payloadvalue: string;
    creditPrevious: string;
    creditLatest: string;
};

// Material-UIのスタイル設定
const useStyles = makeStyles({
    RadarChartStyle : {
      '& > svg': {
        overflow: 'visible',
        transform: 'scale(.9)',
      },
      '& > svg.recharts-surface': {
        paddingBottom: '10px',
      }
    },
});

const CreditRadarChart = ({ data, raderChartSize, raderChartColors }:CreditRadarChartTypeProps ) => {
    const classes = useStyles();

    const RadarChartStyle = {
        width: '100%', 
        height: 'auto', 
        maxWidth: `${raderChartSize[0]}px`, 
        maxHeight: `${raderChartSize[1]}px`,
        margin: 'auto',
    };

    // 項目の横に数値を設置
    const RenderTypeA = ({x, y, textAnchor, payloadvalue, creditPrevious, creditLatest}:RenderTypeProps) => {
        return (
            <>
            <text x={x} y={y} textAnchor={textAnchor}>
                <tspan>{payloadvalue}</tspan>
                <tspan dx="10" fontWeight="bold" fill={raderChartColors[2]}>
                {creditLatest}
                <tspan fontWeight="bold" fill={raderChartColors[0]}>（{creditPrevious}）</tspan>
                </tspan>
            </text>
            </>
        );
    };

    // 項目の下に数値を設置
    const RenderTypeB = ({x, y, textAnchor, payloadvalue, creditPrevious, creditLatest}:RenderTypeProps) => {
        const breaks = payloadvalue.length >= 8 ? true : false;
        const value = breaks ? payloadvalue.replace(/(な|を)/g, '$1|').split('|') : payloadvalue;
        return (
            <>
            <text x={x} y={y} textAnchor={textAnchor}>
                {breaks ? (
                <>
                    <tspan x={x} y={y-15} dy="0">{value[0]}</tspan>
                    <tspan x={x} y={y-15} dy="18">{value[1]}</tspan>
                </>
                ):(
                <>
                    <tspan>{value}</tspan>
                </>
                )}
            </text>
            <text x={x} y={y} textAnchor={textAnchor}>
                <tspan dy="25" fontWeight="bold" fill={raderChartColors[2]}>
                {creditLatest}
                <tspan fontWeight="bold" fill={raderChartColors[0]}>（{creditPrevious}）</tspan>
                </tspan>
            </text>
            </>
        );
    };

    const renderCustomItem = (label: any) => {
        const { x, y, textAnchor, index, payload } = label;
        const values = data;
        const fixY = index === 0 ? y - 10 : index === 2 ? y + 10 : y;
        const renderTypeProps: RenderTypeProps = {
            x: x,
            y: fixY,
            textAnchor: textAnchor,
            payloadvalue: payload.value,
            creditPrevious: values![index].creditPrevious,
            creditLatest: values![index].creditLatest,
        };
        return (
            <>
            {index === 0 && (RenderTypeA({...renderTypeProps}))}
            {index === 1 && (RenderTypeB({...renderTypeProps}))}
            {index === 2 && (RenderTypeA({...renderTypeProps}))}
            {index === 3 && (RenderTypeB({...renderTypeProps}))}
            </>
        );
    };

    return (
    <>
        <RadarChart
            outerRadius={120}
            width={raderChartSize[0]}
            height={raderChartSize[1]}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            className={classes.RadarChartStyle}
            style={RadarChartStyle}
        >
            <PolarGrid />
            <PolarAngleAxis 
                dataKey="subject" 
                tick={renderCustomItem} />
            <PolarRadiusAxis 
                angle={90} 
                domain={[0, 200]} />
            <Radar 
                name="成績 最新" 
                dataKey="creditLatest" 
                stroke={raderChartColors[2]} 
                fill={raderChartColors[2]} 
                fillOpacity={0.2} />
            <Radar 
                name="成績 前回" 
                dataKey="creditPrevious" 
                stroke={raderChartColors[0]} 
                fill={raderChartColors[0]} 
                fillOpacity={0.8} 
            />
            <Radar 
                name="標準値" 
                dataKey="standard" 
                stroke={raderChartColors[4]} 
                fill={raderChartColors[4]} 
                fillOpacity={0.3} 
            />
            <Legend wrapperStyle={{ width: "100%", bottom: "10px", left: "0", fontSize: "max(.7vw, .7rem)" }} />
        </RadarChart>
    </>
    );
};

export default CreditRadarChart;
