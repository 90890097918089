import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  Box,
  styled,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { getCredit, useAuth0Token } from "../common/http-requests";
import { creditRequestType, creditPs8ResponseType, creditResponseType } from "../common/type";
import { Store } from "../store";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
import CreditRadarChart from '../components/CreditRadarChart'; 
import ExpandLessIcon from '@mui/icons-material/ExpandLess';//上矢印
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';//下矢印
import Groups3Icon from '@mui/icons-material/Groups3';//教職コンピテンシー
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';//トランスファラブルスキル

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '10px 20px 15px',
    overflow: 'hidden'
  }
});

// 鳴門教育大学学章のメインカラーである「Utopia Blue」(#226DAE)を基準に指定しました。
const RADAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7",
];

const RADAR_CHART_SIZE: number[] = [
  800, 320
];

const SUBJECT_ITEM: string[] = [
  "教師としての構え",
  "教師として必要な専門知識",
  "教師として必要な基本的技能",
  "教師として必要な実践的指導力",
  "人間性",
  "連携・協働力",
  "課題発見・価値創造力",
  "省察力と職能成長を志向する態度",
];

const AccordionLabel = styled(Box)(() => ({
  position: "relative",
  width: '100%', 
  height: 'auto', 
  maxWidth: `${RADAR_CHART_SIZE[0]}px`, 
  margin: '8px auto 0',
  borderTop: `solid 1px ${RADAR_CHART_COLORS[2]}`,
  borderBottom: `solid 1px ${RADAR_CHART_COLORS[2]}`,
  color: `${RADAR_CHART_COLORS[2]}`,
}));

const AccordionButton = styled(Box)(() => ({
  position: "absolute",
  display: "inline-block",
  top: "0",
  right: "0",
}));

const Accordion = styled(Box)(() => ({
  overflow: 'hidden', 
  transition: 'max-height 0.3s ease-out',
}));

/**
const DefaultStagePoint: creditPs8ResponseType = {
  ps8_credit_1: 0.0,
  ps8_credit_2: 0.0,
  ps8_credit_3: 0.0,
  ps8_credit_4: 0.0,
  ps8_credit_5: 0.0,
  ps8_credit_6: 0.0,
  ps8_credit_7: 0.0,
  ps8_credit_8: 0.0,
};
*/

const CreditPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  const [isSEOpen, setIsSEOpen] = useState(true);
  const [seHeight, setSEHeight] = useState('0px');
  const [isTSOpen, setIsTSOpen] = useState(true);
  const [tsHeight, setTSHeight] = useState('0px');

  // データ例
  //   "previous_credits":
  //     { "ps8_credit_1": 160, "ps8_credit_2": 235, "ps8_credit_3": 188, "ps8_credit_4": 79, "ps8_credit_5": 192, "ps8_credit_6": 188, "ps8_credit_7": 329, "ps8_credit_8": 105 },
  //   "latest_credits":
  //     { "ps8_credit_1": 171, "ps8_credit_2": 239, "ps8_credit_3": 195, "ps8_credit_4": 83, "ps8_credit_5": 196, "ps8_credit_6": 196, "ps8_credit_7": 332, "ps8_credit_8": 120 }
  /**
  const [creditData, setCreditData] = useState<creditResponseType>({
    previous_credits: DefaultStagePoint,
    latest_credits: DefaultStagePoint,
  });
   */

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  const [seData, setSEData] = useState<any[]>(); // 教職コンピテンシー
  const [tsData, setTSData] = useState<any[]>(); // トランスファラブルスキル
  
  const [accordionHeight] = useState("38vw"); // Mobile時のAccordionnの高さ指定　// 40px

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  const createData = (data: creditResponseType) => {
    const p_c = data.previous_credits;
    const l_c = data.latest_credits;
    const defaultValue = 0.0; //初期値
    const standardValue = 100; //標準値
    const fullMarkValue = 200; //MAX値
    /** creditPrevious:前回 creditLatest:最新 */
    const se_data = [
      { subject: SUBJECT_ITEM[0], 
        creditPrevious: p_c.ps8_credit_1 || defaultValue,
        creditLatest: l_c.ps8_credit_1 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[1], 
        creditPrevious: p_c.ps8_credit_2 || defaultValue, 
        creditLatest: l_c.ps8_credit_2 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[2], 
        creditPrevious: p_c.ps8_credit_3 || defaultValue, 
        creditLatest: l_c.ps8_credit_3 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[3], 
        creditPrevious: p_c.ps8_credit_4 || defaultValue, 
        creditLatest: l_c.ps8_credit_4 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
    ];
    const ts_data = [
      { subject: SUBJECT_ITEM[4], 
        creditPrevious: p_c.ps8_credit_5 || defaultValue, 
        creditLatest: l_c.ps8_credit_5 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[5], 
        creditPrevious: p_c.ps8_credit_6 || defaultValue, 
        creditLatest: l_c.ps8_credit_6 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[6], 
        creditPrevious: p_c.ps8_credit_7 || defaultValue, 
        creditLatest: l_c.ps8_credit_7 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[7], 
        creditPrevious: p_c.ps8_credit_8 || defaultValue, 
        creditLatest: l_c.ps8_credit_8 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
    ];
    setSEData(se_data);
    setTSData(ts_data);
    /** 試験用
    const se_data = [
      { subject: SUBJECT_ITEM[0], 
        creditPrevious: 120 || defaultValue,
        creditLatest: 200 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[1], 
        creditPrevious: 90 || defaultValue, 
        creditLatest: 150 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[2], 
        creditPrevious: 120 || defaultValue, 
        creditLatest: 180 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[3], 
        creditPrevious: 130 || defaultValue, 
        creditLatest: 140 || defaultValue,
        standard: standardValue, 
        fullMark: fullMarkValue },
    ];
    const ts_data = [
      { subject: SUBJECT_ITEM[4], 
        creditPrevious: 90 || defaultValue, 
        creditLatest: 120 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[5], 
        creditPrevious: 130 || defaultValue, 
        creditLatest: 180 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[6], 
        creditPrevious: 120 || defaultValue, 
        creditLatest: 140 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
      { subject: SUBJECT_ITEM[7], 
        creditPrevious: 100 || defaultValue, 
        creditLatest: 110 || defaultValue, 
        standard: standardValue, 
        fullMark: fullMarkValue },
    ];
    setSEData(se_data);
    setTSData(ts_data);
     */
  }

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getCreditData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getCreditData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: creditRequestType = {
        login_user_id,
      };
      const data: creditResponseType = await getCredit(
        await getToken(),
        requestParams
      );
      // setCreditData(data);
      createData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setSEHeight(isSEOpen ? `${RADAR_CHART_SIZE[1]}px` : '0px');
  }, [isSEOpen]);

  useEffect(() => {
    setTSHeight(isTSOpen ? `${RADAR_CHART_SIZE[1]}px` : '0px');
  }, [isTSOpen]);
  
  const handleSEAccordion = () => {
    setIsSEOpen(!isSEOpen);
  };

  const handleTSAccordion = () => {
    setIsTSOpen(!isTSOpen);
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            <Paper className={classes.chartContainer}>
              <AccordionLabel>
                <Groups3Icon sx={{ margin: "0 10px 0 5px" }}/>
                教職コンピテンシー
                <AccordionButton onClick={handleSEAccordion}>
                  {isSEOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </AccordionButton>
              </AccordionLabel>
              <Accordion sx={{ height: isDesktop && isSEOpen ? seHeight : isMobile && isSEOpen ? accordionHeight : seHeight }}>
                <CreditRadarChart
                  raderChartSize={RADAR_CHART_SIZE}
                  data={seData}
                  raderChartColors={RADAR_CHART_COLORS}
                />
              </Accordion>

              <AccordionLabel>
                <SettingsAccessibilityIcon sx={{ margin: "0 10px 0 5px" }}/>
                トランスファラブルスキル
                <AccordionButton onClick={handleTSAccordion}>
                  {isTSOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </AccordionButton>
              </AccordionLabel>
              <Accordion sx={{ height: isDesktop && isTSOpen ? tsHeight : isMobile && isTSOpen ? accordionHeight : tsHeight }}>
                <CreditRadarChart
                  raderChartSize={RADAR_CHART_SIZE}
                  data={tsData}
                  raderChartColors={RADAR_CHART_COLORS}
                />
              </Accordion>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default CreditPage;
