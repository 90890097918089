import { createTheme } from "@mui/material/styles";

export const myTheme = createTheme({
  palette: {
    primary: {
      // main: "#4BA82C",
      main: "#226DAE", // 鳴門教育大学学章の「Utopia Blue」
    },
    // secondary: {
    //   main: '#1C4196',
    // },
  },
  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "Arial",
      '"Hiragino Kaku Gothic ProN"',
      '"Hiragino Sans"',
      '"BIZ UDPGothic"',
      "Meiryo",
      "sans-serif",
    ].join(","),
  },
});
