import { FC, useState, useEffect } from "react";
import {
  Container,
} from "@mui/material";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";

const DiplomaSupplementPage: FC = () => {
  const [isSending, setIsSending] = useState(true);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    setIsSending(true);
    try {
      ;
    } catch {
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            ※実装中：ディプロマ・サプリメント画面
            {/* </Box> */}
          </>
        )}
      </Container>
    </>
  );
};

export default DiplomaSupplementPage;
