import React, { FC, useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Pagination,
  Paper,
  TextField,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { useAuth0Token, getChatbotMessages, newChatbotMessage, chatbotLLMMessageStream, updateChatbotLLMMessage } from "../common/http-requests";
import { chatbotMessagesRequestType, chatbotMessageResponseType, chatbotMessagesResponseType, newChatbotMessageRequestType, chatbotLLMMessageRequestType, updateChatbotMessageRequestType } from "../common/type";
import { DatePickerComponent } from "../components/DatePickerComponent";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { FormOutlineAtom } from "../components/atoms/FormOutlineAtom";
import { PaddingAtom } from "../components/atoms/PaddingAtom";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { Store } from "../store";

const DefaultPageIndex = -1; // 末尾を表す
const DefaultCountPerPage = 1;

const DEFAULT_SEARCH_WORDS = "";
const DEFAULT_POST_MESSAGE_TEXT = "";
// const DEFAULT_IS_RESET_AND_NEWLY_POST = false;

const CHATBOT_MESSAGE_MAX_LENGTH = 1000;  // 投稿文の最大文字数
const MESSAGE_ROLE_TYPE_USER = 0; // 投稿種別としての「ユーザ：0」
const PAGINATION_DIFF_INDEX = 1;  // TablePagination(0起点) と Pagination(1起点) との起点の差異

const MSG_STATUS_FAILED = 1;

const useStyles = makeStyles({
  // TODO：仮実装。レイアウト調整

  // 投稿内容
  chatMessage: {
    margin: "10px 0",
  },
  userChatMessage: {
    marginLeft: "0",
  },
  assistantChatMessage: {
    marginLeft: "20px",
  },

  // 投稿本文
  chatMessageText: {
    borderRadius: "10px",
    padding: "10px",
  },
  userChatMessageText: {
    borderWidth: "2px",
  },
  assistantChatMessageText: {
    borderWidth: "1px",
    backgroundColor: "#F0FAFA", // "#EEF8FC",
  }
});


const ChatbogPage: FC = () => {
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles(); // chart用
  const [isSending, setIsSending] = useState(true);
  const [isLLMStreaming, setIsLLMStreaming] = useState(false);

  // 会話履歴
  const [messagesData, setMessagesData] = useState<chatbotMessagesResponseType>({
    block_count: 0,
    page_index: DefaultPageIndex,
    content: [],
  });

  // 検索条件：開始日
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [parsedStartDate, setParsedStartDate] = useState<string>("");
  // 検索条件：単語
  const [searchWords, setSearchWords] = useState<string>(DEFAULT_SEARCH_WORDS);

  // 投稿文
  const [postMessageText, setPostMessageText] = useState<string>(DEFAULT_POST_MESSAGE_TEXT);
  // 新しい会話で投稿するかどうか
  // const [isResetAndNewlyPost, setIsResetAndNewlyPost] = useState<boolean>(DEFAULT_IS_RESET_AND_NEWLY_POST);

  // メッセージ一覧のページ位置と、ページ単位でのページ数（ブロック数）
  const [pageIndex, setPageIndex] = useState(DefaultPageIndex);
  // MEMO: TablePagination ではページ数切替UIがあるため、用意していた名残
  const [countPerPage, setCountPerPage] = useState(DefaultCountPerPage);
  // ページネーションの数
  const paginationCount = Math.ceil(messagesData.block_count / countPerPage);

  const setPageIndexForPagination = (newPageIndex: number) => {
    if (newPageIndex === DefaultPageIndex) {
      setPageIndex(newPageIndex);
    } else {
      setPageIndex(newPageIndex + PAGINATION_DIFF_INDEX); // MEMO: Pagination の場合、1起点なので調整
    }
  };

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // YYYY/MM/DDに変換
  const parseDate = (period: Date | null) => {
    const year = period?.getFullYear();
    // getMonthの値は0起点のため+1
    const month = period ? period.getMonth() + 1 : undefined;
    const date = period?.getDate();

    if (!(year && month && date)) return "";
    return `${year}/${month}/${date}`;
  };

  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getChatbotMessagesData(
          pageIndex,
          countPerPage,
          Number(state.userId),
          parsedStartDate,
          searchWords
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  // 日付が選択されるたびに startDate を更新
  useEffect(() => {
    startDate && setParsedStartDate(parseDate(startDate));
  }, [startDate]);

  // 会話履歴一覧の取得
  const getChatbotMessagesData = async (
    page_index: number,
    count_per_page: number,
    login_user_id: number, // userResponseType.id の値
    start_date: string, // yyyy/mm/dd形式
    search_words: string, // 空白区切り(OR)の検索文字列
  ) => {
    setIsSending(true);
    try {
      const requestParams: chatbotMessagesRequestType = {
        page_index,
        count_per_page,
        login_user_id,
        start_date,
        search_words,
      };
      const data: chatbotMessagesResponseType = await getChatbotMessages(
        await getToken(),
        requestParams
      );
      setMessagesData(data);
      setPageIndexForPagination(data.page_index);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  // 会話の投稿
  const postChatbotMessage = async (
    login_user_id: number,  // userResponseType.id の値
    msg_text: string,       // 会話本文
    // reset_and_newly: boolean, // 新しい会話を始める場合 true
  ) => {
    setIsSending(true);
    try {
      // 新規投稿API呼び出し
      const requestParams: newChatbotMessageRequestType = {
        login_user_id,
        msg_text,
        // reset_and_newly,
      };
      await newChatbotMessage(
        await getToken(),
        requestParams
      );
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "投稿に失敗しました。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  // LLM回答の要求
  const getChatbotLLMMessage = async (
    login_user_id: number,  // userResponseType.id の値
  ) => {
    setIsLLMStreaming(true);
    try {
      // 新規投稿API呼び出し
      const requestParams: chatbotLLMMessageRequestType = {
        login_user_id,
      };

      const streamResponse = await chatbotLLMMessageStream(
        await getToken(),
        requestParams
      );
      if (streamResponse.body == undefined) {
        return;
      }

      const reader = streamResponse.body.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      const processStream = async () => {
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          const chunk_text = decoder.decode(value, { stream: true });
          buffer += chunk_text;
          streamLastLLMMessage(buffer);
        }
      };
      await processStream();

      // LLMの回答をFIXさせる（DB内の最後の内容を更新する）
      _updateLastChartbotLLMMessage(login_user_id, buffer, undefined);
    } catch (e) {
      // LLM回答中にエラーが発生したことをDBにログ的に保存する
      _updateLastChartbotLLMMessage(login_user_id, "", MSG_STATUS_FAILED);
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "投稿に失敗しました。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsLLMStreaming(false);
    }
  };

  const _updateLastChartbotLLMMessage = async (login_user_id: number, msg_text: string, msg_status: number | undefined) => {
    try {
      const requestParamsForLLMMessage: updateChatbotMessageRequestType = {
        login_user_id: login_user_id,
        msg_text: msg_text,
        msg_status: msg_status,
      };
      await updateChatbotLLMMessage(
        await getToken(),
        requestParamsForLLMMessage
      );
    } catch(e) {
      // NOP
    }
  };

  const streamLastLLMMessage = (newMessage: string) => {
    setMessagesData(prevMessagesData => {
      const newContent = [...prevMessagesData.content];
      const lastIndex = newContent.length - 1;

      // 最後の要素の msg_text を更新
      newContent[lastIndex] = {
        ...newContent[lastIndex],
        msg_text: newMessage
      };
      return { ...prevMessagesData, content: newContent };
    });
  };

  const handleSetSearchWords = (e: any) => {
    setSearchWords(e.target.value);
  };

  const handleSetPostMessageText = (e: any) => {
    setPostMessageText(e.target.value);
  };

  // 検索ボタン押下時
  const handleClickSearchButton = () => {
    setPageIndexForPagination(DefaultPageIndex);
    getChatbotMessagesData(
      DefaultPageIndex,
      countPerPage,
      Number(state.userId),
      parsedStartDate,
      searchWords
    );
  };

  // クリアボタン押下時
  const handleClickClearButton = () => {
    clearSearchCondition();
  };
  const clearSearchCondition = () => {
    setStartDate(undefined);
    setParsedStartDate(DEFAULT_POST_MESSAGE_TEXT);
    setSearchWords(DEFAULT_SEARCH_WORDS)
  };

  // ページ切り替え
  const handleChangePage = (event: any, newPageIndex: any) => {
    setPageIndexForPagination(newPageIndex);
    getChatbotMessagesData(
      newPageIndex - PAGINATION_DIFF_INDEX, // MEMO: Pagination の場合、1起点なので調整
      countPerPage,
      Number(state.userId),
      parsedStartDate,
      searchWords
    );
  };

  // 投稿ボタン
  const handleClickPostMessageButton = async () => {
    const login_user_id = Number(state.userId);

    await postChatbotMessage(
      login_user_id,
      postMessageText,
      // isResetAndNewlyPost
    );
    setPostMessageText(DEFAULT_POST_MESSAGE_TEXT);
    // setIsResetAndNewlyPost(DEFAULT_IS_RESET_AND_NEWLY_POST);

    // 検索条件をクリアして、会話一覧を取得
    clearSearchCondition();
    setPageIndexForPagination(DefaultPageIndex);
    await getChatbotMessagesData(
      DefaultPageIndex,
      countPerPage,
      login_user_id,
      parsedStartDate,
      searchWords
    );

    // LLM回答を要求
    await getChatbotLLMMessage(
      login_user_id
    );
  };

  // ページ数変更
  // const handleChangeRowsPerPage = (event: any) => {
  //   const newCountPerPage = parseInt(event.target.value);
  //   setCountPerPage(newCountPerPage);

  //   // setPageIndex(DefaultPageIndex);
  //   setPageIndexForPagination(DefaultPageIndex);
  //   getChatbotMessagesData(
  //     DefaultPageIndex,
  //     newCountPerPage,
  //     Number(state.userId),
  //     parsedStartDate,
  //     searchWords
  //   );
  // };

  // LLMストリーミング中は、会話投稿欄等はグレーアウトする
  const disableInLLMStreaming = isLLMStreaming;

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="会話情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                justifyContent: 'center',
                padding: "20px 0 20px 0",
              }}
            >
              {/* TODO: LLM回答中はグレーアウトする？ */}
              <InputFormMolecule inputLabel="日付" widthValue="50px">
                <div className="flex items-center relative z-20">
                  <DatePickerComponent
                    reportDate={startDate}
                    setReportDate={setStartDate}
                  ></DatePickerComponent>
                </div>
              </InputFormMolecule>
              <WidthPaddingBetweenControls />

              <InputFormMolecule inputLabel="単語" widthValue="50px">
                <TextField
                  label="空白区切りで複数指定"
                  value={searchWords ?? DEFAULT_SEARCH_WORDS}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleClickSearchButton();
                    }
                  }}
                  onChange={(e) => handleSetSearchWords(e)}
                  style={{ width: "200px", zIndex: 0 }}
                />
              </InputFormMolecule>
              <WidthPaddingBetweenControls />

              {/* TODO: ボタンが日付や単語と並ぶように上下中央配置 */}
              <div className="flex items-top h-fit">
                <Button
                  disabled={disableInLLMStreaming}
                  style={{
                    width: "100px",
                    zIndex: 0,
                    fontSize: "16px",
                  }}
                  variant="contained"
                  onClick={() => handleClickSearchButton()}
                >
                  検索
                </Button>
                <Button
                  disabled={disableInLLMStreaming}
                  style={{
                    width: "100px",
                    zIndex: 0,
                    fontSize: "16px",
                    marginLeft: "20px",
                    backgroundColor: "gray",
                    transitionProperty: "opacity",
                    transitionDuration: "300ms",
                  }}
                  className="hover:opacity-100 opacity-70"
                  variant="contained"
                  onClick={() => handleClickClearButton()}
                >
                  クリア
                </Button>
              </div>
            </Box>

            <Paper style={{ width: "100%", padding: "10px" }} elevation={0}>
              {/* TODO：仮実装。レイアウト調整 */}
              {messagesData.content.map(
                (value: chatbotMessageResponseType, index) => (
                  <div key={index} className={`${classes.chatMessage}
                    ${(value.role_type === MESSAGE_ROLE_TYPE_USER)
                      ? classes.userChatMessage
                      : classes.assistantChatMessage}`}>
                    {value.role_type === MESSAGE_ROLE_TYPE_USER ? (
                      <><PersonOutlineOutlinedIcon /></>
                    ) : (
                      <><SentimentSatisfiedOutlinedIcon /></>
                    )}
                    <span>　</span>
                    {value.msg_datetime}
                    <div className={`whitespace-pre-wrap ${classes.chatMessageText}
                      ${(value.role_type === MESSAGE_ROLE_TYPE_USER)
                        ? classes.userChatMessageText
                        : classes.assistantChatMessageText}`}>
                      {value.msg_text}
                    </div>
                  </div>
                )
              )}

              {/* TODO: ページネーションUIと投稿UIを画面下部に固定表示したいかも */}
              <PaddingAtom></PaddingAtom>
              <Pagination
                disabled={disableInLLMStreaming}
                sx={{ display: "flex", justifyContent: "center" }}
                // boundaryCount={3}
                count={paginationCount}
                size="large"
                page={pageIndex}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
              />
            </Paper>

            <Box
              sx={{
                padding: "20px 0 20px 0",
                width: "100%",
                // position: "absolute",
                // bottom: 0,
              }}
            >
              {/* <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disableInLLMStreaming}
                        checked={isResetAndNewlyPost}
                        onClick={() => setIsResetAndNewlyPost(!isResetAndNewlyPost)}
                      />
                    }
                    label="新しい会話を始める"
                  />
                </FormGroup>
              </Box> */}

              <InputFormMolecule widthValue="0">
                <Box sx={{ width: "100%" }}>
                  <FormOutlineAtom
                    error={postMessageText?.length > CHATBOT_MESSAGE_MAX_LENGTH && "error"}
                    mainTextLength={postMessageText?.length}
                    mainText={true}
                  >
                    <textarea
                      disabled={disableInLLMStreaming}
                      className="p-4 focus:outline-none w-[100%]"
                      onChange={(e) => handleSetPostMessageText(e)}
                      value={postMessageText ?? DEFAULT_POST_MESSAGE_TEXT}
                      // placeholder="1000文字まで入力できます"
                      wrap="hard"
                      rows={1}
                    ></textarea>
                  </FormOutlineAtom>
                </Box>
                <WidthPaddingBetweenControls />
                <Button
                  disabled={disableInLLMStreaming}
                  style={{
                    width: "100px",
                    zIndex: 0,
                    fontSize: "16px",
                  }}
                  variant="contained"
                  onClick={() => handleClickPostMessageButton()}
                >
                  <SendIcon />
                </Button>
              </InputFormMolecule>
            </Box>
          </>
        )}
      </Container >
    </>
  );
};

// コントロール間のパディング
const WidthPaddingBetweenControls = () => {
  return (
    <div className='w-[3%]'></div>
  )
}

export default ChatbogPage;
