import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
/** @カスタムDOT
import FavoriteIcon from '@mui/icons-material/Favorite'; //構え
import AutoStoriesIcon from '@mui/icons-material/AutoStories'; //知識
import SettingsIcon from '@mui/icons-material/Settings'; //技能
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';//指導力
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';//丸
import PersonIcon from '@mui/icons-material/Person';//人間性
import HandshakeIcon from '@mui/icons-material/Handshake';//協働力
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';//価値創造力
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';//職能成長志向
 */

interface SETSLineChartTypeProps {
    scoreListData: any;
    lineDataKeys: any;
    seriesNameListData: any;
    raderChartSize: number[];
    raderChartColors: string[];
    targetData: string;
};

// Material-UIのスタイル設定
const useStyles = makeStyles({
    LineChartStyle: {
        '& > svg': {
            overflow: 'visible',
        },
        '& > svg.recharts-surface': {
            paddingBottom: '10px', //'40px',
        }
    },
    LegendStyle: {
        padding: "0px",
        margin: "0px",
        textAlign: "center",
        '& > li': {
            display: "inline-block",
            marginRight: "10px;",
        },
        '& > li > span': {
            verticalAlign: "middle",
        }
    }
});

const LINE_CHART_DASHARRAY: string[] = [
    "4", "5 5", "1 1", "7 3 7",
];

const SETSLineChart = ({ scoreListData, lineDataKeys, seriesNameListData, raderChartSize, raderChartColors, targetData }: SETSLineChartTypeProps) => {
    const classes = useStyles();
    /** @カスタムDOT
    const [isData] = useState({
        SE: "SE",
        TS: "TS",
    });
    */

    const LineChartStyle = {
        width: '100%',
        height: 'auto',
        maxWidth: `${raderChartSize[0]}px`,
        maxHeight: `${raderChartSize[1]/*+50*/}px`,
        margin: 'auto',
    };

    /** @カスタムDOT
    const renderLegend = (props: any) => {
        const { payload } = props;
        return (
            <ul className={classes.LegendStyle}>
            {
                payload.map((entry: string, index: number) => (
                <li key={`se-${index}`}>
                    {index === 0 && targetData === isData.SE ? (
                    <FavoriteIcon style={{color: raderChartColors[index]}}/>
                    ) : 
                    index === 1 && targetData === isData.SE ? (
                    <AutoStoriesIcon style={{color: raderChartColors[index]}}/>
                    ) :
                    index === 2 && targetData === isData.SE ? (
                    <SettingsIcon style={{color: raderChartColors[index]}}/>
                    ) :
                    index === 3 && targetData === isData.SE ? (
                    <SwitchAccessShortcutIcon style={{color: raderChartColors[index]}}/>
                    ) :
                    index === 0 && targetData === isData.TS ? (
                        <PersonIcon style={{color: raderChartColors[index]}}/>
                    ) : 
                    index === 1 && targetData === isData.TS ? (
                        <HandshakeIcon style={{color: raderChartColors[index]}}/>
                    ) :
                    index === 2 && targetData === isData.TS ? (
                        <TipsAndUpdatesIcon style={{color: raderChartColors[index]}}/>
                    ) :
                    index === 3 && targetData === isData.TS ? (
                        <PsychologyAltIcon style={{color: raderChartColors[index]}}/>
                    ) : (
                        <FiberManualRecordIcon style={{color: raderChartColors[index]}}/>
                    )}
                    <span>{entry}</span>
                </li>
                ))
            }
            </ul>
        );
    };

    const renderLineMarker = (props: any) => {
        const { cx, cy, dataKey, payload, key } = props;
        const keyset = `${dataKey}-${payload.categoryLabel}-${key}`;
        return (
            <>
            <svg key={keyset} x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
                {dataKey === '構え' && targetData === isData.SE ? (
                <FavoriteIcon style={{color: raderChartColors[0]}}/>
                ) : 
                dataKey === '知識' && targetData === isData.SE ? (
                <AutoStoriesIcon style={{color: raderChartColors[1]}} />
                ) :
                dataKey === '技能' && targetData === isData.SE ? (
                <SettingsIcon style={{color: raderChartColors[2]}}/>
                ) :
                dataKey === '実践的指導力' && targetData === isData.SE ? (
                <SwitchAccessShortcutIcon style={{color: raderChartColors[3]}} />
                ) :
                dataKey === '人間性' && targetData === isData.TS ? (
                    <PersonIcon style={{color: raderChartColors[0]}}/>
                ) : 
                dataKey === '連携・協働力' && targetData === isData.TS ? (
                    <HandshakeIcon style={{color: raderChartColors[1]}} />
                ) :
                dataKey === '課題発見・価値創造力' && targetData === isData.TS ? (
                    <TipsAndUpdatesIcon style={{color: raderChartColors[2]}}/>
                ) :
                dataKey === '省察力・職能成長志向' && targetData === isData.TS ? (
                    <PsychologyAltIcon style={{color: raderChartColors[3]}} />
                ) : (
                    <FiberManualRecordIcon style={{color: raderChartColors[0]}} />
                )}
            </svg>
            </>
        );
    };
     */

    return (
        <>
            <LineChart
                width={raderChartSize[0]}
                height={raderChartSize[1]}
                data={scoreListData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                className={classes.LineChartStyle}
                style={LineChartStyle}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="response_date" />
                <YAxis domain={[0.0, 7.0]} ticks={[0, 1, 2, 3, 4, 5, 6, 7]} />
                <Tooltip />
                <Legend
                    wrapperStyle={{ width: "100%", bottom: "5px", fontSize: "max(.7vw, .6rem)" }}
                /** @カスタムDOT
                payload={seriesNameListData} 
                content={renderLegend}
                */
                />
                {
                    lineDataKeys.map((labelName: string, index: number) => (
                        <Line
                            key={labelName}
                            type="linear"
                            dataKey={labelName}
                            name={seriesNameListData[index]}
                            stroke={raderChartColors[index % raderChartColors.length]}
                            activeDot={{ r: 8 }}
                            strokeDasharray={LINE_CHART_DASHARRAY[index % raderChartColors.length]}
                            strokeWidth={2}
                        /** @カスタムDOT
                         dot={renderLineMarker} 
                        */
                        />
                    ))
                }
            </LineChart>
        </>
    );
};

export default SETSLineChart;
