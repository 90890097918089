import { sendLog } from "../common/http-requests";
import {
  loggingRequestType,
  loggingResponseType,
} from "../common/type";

// 編集可能かどうかの確認（userIdが0でないかどうか）
// ページ表示時のハンドリングも考慮して、backend ではなく frontend で対応しておく。
export const checkValidUserId = async (
  userId: number,
  userEmail: string,
  funcAuth0Token: any,
  funcSetFeedbackInfo: any,
  feedbackInfo: any
) => {
  if (userId > 0) {
    return true;
  }

  let log_text = "Invalid user id. email:" + userEmail;
  const requestParams: loggingRequestType = {
    log_text,
  };
  const result: loggingResponseType = await sendLog(
    await funcAuth0Token(),
    requestParams
  );

  funcSetFeedbackInfo({
    ...feedbackInfo,
    open: true,
    text: "ユーザ情報が不正です。ログアウト後、再ログインしてください。",
    type: "error",
  });
  return false;
};
