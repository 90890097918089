export const FeedbackBar = (props: any) => {
  const { feedbackInfo, handleClose, className } = props;

  const isSuccess = feedbackInfo.type === "success";

  return (
    <div className="relative z-50">
      <div
        id="alert-border-2"
        className={`flex p-4 mb-4 border-t-4
                ${isSuccess ? "text-green-800 border-green-300 bg-green-50" : "text-red-800 border-red-300 bg-red-50"} 
                fixed top-4 left-1/2 -translate-x-1/2 w-[50%] 
                transition duration-700 ease-in-out opacity-0 translate-y-20 pointer-events-none ${feedbackInfo.open === true && "opacity-100 translate-y-0 z-50"
          }${className}
                `}
        role="alert"
      >
        <svg
          className="flex-shrink-0 w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          ></path>
        </svg>
        <div className="ml-3 text-sm font-medium">{feedbackInfo.text}</div>
        <button
          type="button"
          className={`ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8 pointer-events-auto
        ${isSuccess ? "bg-green-50 focus:ring-green-400 hover:bg-green-200" : "bg-red-50 focus:ring-red-400 hover:bg-red-200"}
        `}
          data-dismiss-target="#alert-border-2"
          aria-label="Close"
          onClick={() => handleClose()}
        >
          <span className="sr-only">Dismiss</span>
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  );
};
