import React, { FC, useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // chart用
import { makeStyles } from '@material-ui/core/styles'; // chart用

import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { DatePickerComponent } from "../components/DatePickerComponent";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { PaddingAtom } from "../components/atoms/PaddingAtom";

import {
  portfolioRequestType,
  portfolioResponseType,
  standardTagsResponseType,
  reportType,
} from "../common/type";
import {
  useAuth0Token,
  getStandardTags,
  getPortfolio,
} from "../common/http-requests";

import { registerLocale } from "react-datepicker";
import { ja } from 'date-fns/locale';
import { Store } from "../store";

// Material-UI スタイルの定義　chart用
const useStyles = makeStyles({
  chartContainer: {
    padding: '20px',
  },
  minContainer: {
    position: 'relative',
    marginTop: '60px',
  },
  BarChartStyle: {
    '& > svg.recharts-surface': {
      overflow: 'visible',
    }
  }
});

// TODO とりあえず、見極め可能な8色でグラデーションをつけている。
// 今後、学内から進学した大学院生においては、12色（６年×上期・下期）必要なケースがあるかもしれないので、検討が必要。
const BAR_CHART_COLORS: string[] = [
  "#72BDFE","#62ADEE","#529DDE","#428DCE","#327DBE","#226DAE","#125D9E","#024D8E" 
];

type ReportItem  = { index: number, report: reportType };
type ReportTypesList = {
  list: ReportItem [];
  width: string;
}[];

export const PortfolioPage: FC = () => {
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles(); // chart用
  const { isDesktop, isPortfolioChart } = useCustomMediaQuery();
  const [ isTickCount, setIsTickCount ] = useState<number>();

  //日付登録 データの型を `Date | null` にして、初期値を `null` に設定することで、初期表示時に未設定にする
  const [reportDate, setReportDate] = useState<Date | null>(null);
  registerLocale("ja", ja);

  //Backendから送信されたportfolioReportデータ
  const [portfolioData, setportfolioData] = useState<portfolioResponseType>({
    portfolioReportDataList: [],
    labels:[],
  });

   // YYYY/MM/DDに変換
  const parseDate = (period: Date | null) => {
    const year = period?.getFullYear();
    // getMonthの値は0起点のため+1
    const month = period ? period.getMonth() + 1 : undefined;
    const date = period?.getDate();

    if (!(year && month && date)) return "";
    return `${year}/${month}/${date}`;
  };

  // 開始日
  const [startDate, setStartDate] = useState<Date | undefined>(
    reportDate ? reportDate : undefined
  );
  const [parsedStartDate, setParsedStartDate] = useState<string>(
    reportDate ? parseDate(reportDate) : ""
  );
  // 終了日
  const [endDate, setEndDate] = useState<Date | undefined>(
    reportDate ? reportDate : undefined
  );
  const [parsedEndDate, setParsedEndDate] = useState<string>(
    reportDate ? parseDate(reportDate) : ""
  );

  //鳴門パースペクティブ
  const [standardTags, setStandardTags] = useState<standardTagsResponseType>({
    content: [],
  });

  const [selectedStandardTagsArray, setSelectedStandardTagsArray] = useState<
    Array<any>
  >([]);

  //鳴門パースペクティブを空白区切りの文字列に変換
  const [selectedStandardTags, setSelectedStandardTags] = useState<string>("");

  //#ハッシュタグ
  const [userTags, setUserTags] = useState<string>("");

  // 記録種類のリスト
  const [reportTypes, setReportTypes] = useState<number[]>([0, 1, 2, 3, 4, 5, 99]);
  const reportTypesArray: Array<reportType> = [
    { id: 0, value: "セルフデザイン型学修 - 週録" },
    { id: 1, value: "教育実習 - 週録" },
    { id: 2, value: "セルフデザイン型学修 - 省察（前期末／後期末）" },
    { id: 3, value: "セルフデザイン型学修 - セルデザクエスト" },
    { id: 4, value: "教育実習 - 日録" },
    { id: 5, value: "教育実習 - 全体の振り返り" },
    { id: 99, value: "その他" },
  ];

  const createReportTypesList: ReportTypesList = [
    {
      list: [
        { index: 0, report: reportTypesArray[0] },
        { index: 2, report: reportTypesArray[2] },
        { index: 3, report: reportTypesArray[3] }
      ],
      width: "30vw"
    },
    {
      list: [
        { index: 4, report: reportTypesArray[4] },
        { index: 1, report: reportTypesArray[1] },
        { index: 5, report: reportTypesArray[5] }
      ],
      width: "20vw"
    },
    {
      list: [
        { index: 6, report: reportTypesArray[6] }
      ],
      width: "10vw"
    }
  ];

  const pickTickCount = (data: any[]) => {
    let extractData = [];
    for (let i = 0; i < data.length; i++) {
      const value = data[i];
      const dynamicKey = Object.keys(value).find(key => key !== 'categoryLabel');
      if(dynamicKey){
        const secondValue = value[dynamicKey];
        extractData.push(secondValue)
      }
    };
    const maxValue = Math.max(...extractData);
    setIsTickCount(maxValue+1); //+1しないとy軸が1間隔にならない
  };

  //reportTypesのチェックボックスを管理するためのisChecked
  const [isChecked, setIsChecked] = useState<boolean[]>(
    //@ts-ignore
    reportTypesArray.slice().fill(false) as boolean[]
  );
  const toggleCheckboxValue = (index: number) => {
    setIsChecked(isChecked.map((v, i) => (i === index ? !v : v)));
  };

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  useEffect(() => {
    const selectedReportTypes: any[] = [];
    reportTypesArray.map((reportType, index) => {
      isChecked[index] && selectedReportTypes.push(reportType.id);
    });
    setReportTypes(selectedReportTypes);
  }, [isChecked]);

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getStandardTagsData();
        await getportfolioData(
          Number(state.userId),
          state.userType,
          parsedStartDate,
          parsedEndDate,
          reportTypes,
          newStandardTagsArray.join(" "), // 初回のみダッシュボードから渡ってきたstateをもとに検索
          userTags,
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  // 日付が選択されるたびにstartDate, endDateを更新
  useEffect(() => {
    startDate && setParsedStartDate(parseDate(startDate));
    endDate && setParsedEndDate(parseDate(endDate));
  }, [startDate, endDate]);

  const newStandardTagsArray: any[] = [];

  // 鳴門パースペクティブを取得してセレクトボックスに表示
  const getStandardTagsData = async () => {
    setIsSending(true);
    try {
      const data: standardTagsResponseType = await getStandardTags(
        await getToken()
      );
      setStandardTags(data);

    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };
  // 空白区切りの文字列に変換
  useEffect(() => {
    const convertSelectedStandardTagsToString = () => {
      setSelectedStandardTags(selectedStandardTagsArray.join(" "));
    };
    convertSelectedStandardTagsToString();
  }, [selectedStandardTagsArray]);

  const getportfolioData = async (
    login_user_id?: number, // userResponseType.id の値
    login_user_type?: number, // 0：教職員、1：学生
    start_date?: string, // yyyy/mm/dd形式
    end_date?: string, // yyyy/mm/dd形式
    report_types?: number[], // 0：週報、1：実習、2：省察、99：その他  TODO：UIと要調整
    standard_tags?: string, // 数値の空白区切り。数値は standardTagResponseType.id の値
    user_tags?: string, // タグ文字列の空白区切り。タグ文字列は任意。
  ) => {
    setIsSending(true);
    try {
      const requestParams: portfolioRequestType = {
        login_user_id,
        start_date,
        end_date,
        report_types,
        standard_tags,
        user_tags,
      };
      const data: portfolioResponseType = await getPortfolio(
        await getToken(),
        requestParams
      );
      pickTickCount(data.portfolioReportDataList);
      setportfolioData(data);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const [isSending, setIsSending] = useState(true);

  const handleSetUserTags = (e: any) => {
    setUserTags(e.target.value);
  };

  //クリアボタンを押したとき
  const handleClickClearButton = () => {
    setStartDate(undefined);
    setParsedStartDate("");
    setEndDate(undefined);
    setParsedEndDate("");
    setReportTypes([0, 1, 2, 99]);
    // @ts-ignore
    setIsChecked(reportTypesArray.slice().fill(false));
    setSelectedStandardTagsArray([]);
    setSelectedStandardTags("");
    setUserTags("");
  };

  // 検索ボタンを押したとき
  const handleClickSearchButton = () => {
    getportfolioData(
      Number(state.userId),
      state.userType,
      parsedStartDate,
      parsedEndDate,
      reportTypes,
      selectedStandardTags,
      userTags,
    );
  };

  const renderCheckboxes = (reportTypes: Array<ReportItem>, isChecked: { [key: number]: boolean }, toggleCheckboxValue: (id: number) => void) => {
    return reportTypes.map((reportType) => (
      <FormGroup key={reportType.report.id}>
        <FormControlLabel
          style={{ zIndex: 0 }}
          control={
            <Checkbox
              value={reportType.report.id}
              checked={isChecked[reportType.index] || false}
              onClick={() => {
                toggleCheckboxValue(reportType.index);
              }}
            />
          }
          label={reportType.report.value}
        />
      </FormGroup>
    ));
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="記録情報を取得中..." />
          </>
        ) : (
          <>
          <VisualizedMenus />
          <FeedbackBar
            feedbackInfo={feedbackInfo}
            handleClose={handleClose}
          ></FeedbackBar>
        <Box className={classes.minContainer}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px 0 20px 0",
              flexDirection: isDesktop ? "row" : "column",
              alignItems: isDesktop ? "flex-start" : "center",
            }}
          >
            <Box
              sx={{
                width: "10%", // ReportListPage.tsxから流用し、一部変更 15%⇒10%
                display: "flex",
                justifyContent: "space-around",
                // border: '2px solid red' // TODO デバッグ用
              }}
            >
            </Box>
             
            <Box sx={{ width: "75%", // ReportListPage.tsxから流用し、一部変更 65%⇒75%
              }}> { /* TODO デバッグ用 border: '2px solid grey*/ } 
              <InputFormMolecule inputLabel="期間" widthValue="180px" /* ReportListPage.tsxから流用し、一部追加 widthValue="180px" */ >
                <div className="flex items-center relative z-20">
                  <DatePickerComponent
                    reportDate={startDate}
                    setReportDate={setStartDate}
                  ></DatePickerComponent>

                  <div className="w-[60px] text-[20px]  text-center">~</div> 
                  <DatePickerComponent
                    reportDate={endDate}
                    setReportDate={setEndDate}
                    minDate={startDate}
                  ></DatePickerComponent>
                </div>
              </InputFormMolecule>
              <InputFormMolecule inputLabel="種類" widthValue="180px" /* ReportListPage.tsxから流用し、一部追加 widthValue="180px" */ >
                <Box 
                  className="flex items-start "
                  style={{flexDirection: isDesktop ? "row" : "column", width: "49vw" }}
                >
                  {createReportTypesList.map((groupedReportTypes, groupedReportTypesIndex) => (
                    <Box 
                      key={`groupedReportTypes-${groupedReportTypesIndex}`} 
                      className="flex items-start flex-col flex-wrap"
                      style={{ width: isDesktop ? groupedReportTypes.width :"100%" }}
                    >
                      {renderCheckboxes(groupedReportTypes.list, isChecked, toggleCheckboxValue)}
                    </Box>
                  ))}
                </Box>
              </InputFormMolecule>
              <InputFormMolecule inputLabel="鳴門パースペクティブ" widthValue="180px" /* ReportListPage.tsxから流用し、一部追加 widthValue="180px" */ > 
                <FormControl
                  sx={{ minWidth: 120, width: "80%" }}
                  size="small"
                >
                  <InputLabel
                    style={{ zIndex: 1, backgroundColor: "white" }}
                    id="standard_tags"
                  >
                    観点を複数選択できます
                  </InputLabel>
                  <Select
                    style={{ zIndex: 0 }}
                    labelId="standard_tags"
                    multiple
                    id="standard_tags"
                    value={selectedStandardTagsArray}
                    renderValue={(selected) => {
                      return selected
                        .map((id: any) => {
                          return standardTags.content.filter(
                            (obj: any) => obj.id === id
                          )[0].tag_name;
                        })
                        .join(", ");
                    }}
                    label="standard_tags"
                    onChange={(event: SelectChangeEvent<any>) => {
                      const {
                        target: { value },
                      } = event;
                      setSelectedStandardTagsArray(
                        // On autofill we get a stringified value.
                        typeof value === "string" ? value.split(",") : value
                      );
                    }}
                  >
                    {standardTags.content.map((standardTag, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={standardTag.id}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <Checkbox
                            checked={
                              selectedStandardTagsArray.filter((id: any) => {
                                return id === standardTag.id;
                              }).length > 0
                            }
                          />
                          <ListItemText primary={standardTag.tag_name} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </InputFormMolecule>
              <InputFormMolecule inputLabel="ハッシュタグ" widthValue="180px" /* ReportListPage.tsxから流用し、一部追加 widthValue="180px" */>
                <TextField
                  label="空白区切りで複数指定できます"
                  value={userTags ?? ""}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleClickSearchButton();
                    }
                  }}
                  onChange={(e) => handleSetUserTags(e)}
                  style={{ width: "80%", zIndex: 0 }}
                />
              </InputFormMolecule>

            </Box>
            <Box>
              <div className="flex items-top h-fit">
                <Button
                  style={{
                    width: "100px",
                    zIndex: 0,
                    fontSize: "16px",
                  }}
                  variant="contained"
                  onClick={() => handleClickSearchButton()}
                >
                  絞込
                </Button>
                <Button
                  style={{
                    width: "100px",
                    zIndex: 0,
                    fontSize: "16px",
                    marginLeft: "20px",
                    backgroundColor: "gray",
                    transitionProperty: "opacity",
                    transitionDuration: "300ms",
                  }}
                  className="hover:opacity-100 opacity-70"
                  variant="contained"
                  onClick={() => handleClickClearButton()}
                >
                  クリア
                </Button>
              </div>
              <PaddingAtom></PaddingAtom>
            </Box>
          </Box>

          { /* chart用 start */}
          <Paper className={classes.chartContainer} style={{ marginBottom: isDesktop ? "0" : "18vw"}}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={portfolioData.portfolioReportDataList}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 25,
                }}
                className={classes.BarChartStyle}
              >
                <CartesianGrid strokeDasharray="3 3" />
                { /* ラベル名とグラフの間にある黒い縦棒をサイズ0で削除し、ラベル名がグラフと接しないようにラベル名を下に7px移動させる */}
                <XAxis 
                  dataKey="categoryLabel" 
                  tickSize={0} 
                  dy={7} 
                  tick={ isDesktop ? { fontSize: 14 } : { fontSize: 8 }}
                  angle={isPortfolioChart ? 0 : isDesktop ? 30 : 60} 
                  textAnchor={isPortfolioChart ? "middle" : "start"}
                /> 
                <YAxis 
                  tickCount={isTickCount} 
                />
                <Tooltip />
                <Legend wrapperStyle={{ width: "100%", bottom: "0", fontSize: "max(1.2vw, .8rem)" }} />
                {
                  portfolioData.labels.map((value: string, index) => {return(
                    <Bar key={index} dataKey={value} stackId="sameBarGraph" fill={BAR_CHART_COLORS[index % BAR_CHART_COLORS.length]} />
                    )
                  }
                  )
                }
              </BarChart>
            </ResponsiveContainer>
          </Paper>
          { /* chart用 end */}

        </Box>
        </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default PortfolioPage;
