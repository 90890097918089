import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { getNCBTScoreDataRef, useAuth0Token } from "../common/http-requests";
import { NCBTRequestType, NCBTDataRefResponseType } from "../common/type";
import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
});

// 鳴門教育大学学章のメインカラーである「Utopia Blue」(#226DAE)を基準に指定しました。
const BAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7","#cfd5ea","#e9ebf5"
];

const NCBTDataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const [tableHeadList, setTableHeadList] = useState<any[]>([]);
  const [tableBodyList, setTableBodyList] = useState<any[]>([]);

  // データ例
  // "data_ref": {
  //   "exam_dates": ['2020/03/24', '2021/04/24', '2022/05/22', '2023/05/24'],
  //   "ps_ncbt_1s": [-0.5, 0.1, 1.0, 0.7],
  //   "ps_ncbt_2s": [-0.3, 0.4, 0.0, -0.1],
  //   "ps_ncbt_3s": [-0.7, 0.0, -0.1, 0.8],
  //   "ps_ncbt_1s": [-0.5, 0.1, 1.0, 0.7],
  // ・英語表記の対応を考慮して、backend API からは日本語表記を返しません。
  // ・exam_dates は受験日を表します。
  // ・ps_ncbt_1s ～ ps_ncbt_4s は、それぞれ「構え」や「知識」についての修得点を表します。
  // const [NCBTScoreDataRefData, setNCBTScoreDataRefData] = useState<NCBTDataRefResponseType>({
  //   data_ref: {}
  // });

  const createData = (data: NCBTDataRefResponseType) => {
    const examDates = data.data_ref.exam_dates || [];

    const earnedList = examDates.map((period: any, index: number) => ({
      exam_dates: period,
    }));
    setTableHeadList(earnedList);

    const psNcbt1s = data.data_ref.ps_ncbt_1s || [];
    const psNcbt2s = data.data_ref.ps_ncbt_2s || [];
    const psNcbt3s = data.data_ref.ps_ncbt_3s || [];
    const psNcbt4s = data.data_ref.ps_ncbt_4s || [];

    const scoreList = psNcbt1s.map((score: number, index: number ) => ({
      ps_ncbt_1s: score,
      ps_ncbt_2s: psNcbt2s[index],
      ps_ncbt_3s: psNcbt3s[index],
      ps_ncbt_4s: psNcbt4s[index],
    }));
    setTableBodyList(scoreList);
    
  };

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getNCBTScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getNCBTScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: NCBTRequestType = {
        login_user_id,
      };
      const data: NCBTDataRefResponseType = await getNCBTScoreDataRef(
        await getToken(),
        requestParams
      );
      createData(data)
      // setNCBTScoreDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const TableBodyCell = () => {
    const label = ["構え", "知識", "技能", "実践的指導力"];
    return (
      <>
      {label.map((lebel: string, rowindex: number) => (
        <TableRow 
          key={`${label}-${rowindex}`}
          sx={{ backgroundColor: rowindex % 2 === 0 ? BAR_CHART_COLORS[5] : BAR_CHART_COLORS[6] }}
        >
          <TableCell sx={{ textAlign: 'center', fontSize: 'max(1rem, 1.8vw)' }}>{lebel}</TableCell>
          {tableBodyList.map((item: any, index: number) => (
            <TableCell 
              key={`myselfScore-${index}`} 
              sx={{ textAlign: 'center', fontSize: 'max(1rem, 1.8vw)' }}
            >
              {rowindex === 0 ? item.ps_ncbt_1s : 
               rowindex === 1 ? item.ps_ncbt_2s : 
               rowindex === 2 ? item.ps_ncbt_3s : 
               item.ps_ncbt_4s }
            </TableCell>
          ))}
        </TableRow>
      ))}
      </>
    )
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead key="table-head">
                    <TableRow>
                      <TableCell key="fast-null" sx={{ textAlign: 'center', backgroundColor: BAR_CHART_COLORS[1] }} />
                      {tableHeadList.map((item: any, index: number) => (
                        <TableCell key={`${item.exam_dates}-${index}`} sx={{ textAlign: 'center', backgroundColor: BAR_CHART_COLORS[1], color: '#ffffff', fontSize: 'max(1rem, 1.5vw)' }}>
                          {item.exam_dates}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody key="table-body">
                    <TableBodyCell />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default NCBTDataRefPage;
