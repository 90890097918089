import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { Badge, Box, styled } from "@mui/material";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import { AddBoxSharp } from "@material-ui/icons";
// import ChatIcon from '@material-ui/icons/Chat';

const PageUrl = [
  { page: "/diplomaSupplement", label: "DS" },
  { page: "/credit", label: "単位" },
  { page: "/trainingIndicator", label: "育成指標" },
  { page: "/gpa", label: "GPA" },
  { page: "/portfolio", label: "Portfolio" },
  { page: "/ncbt", label: "N-CBT" },
  { page: "/sets", label: "SE・TS" },
  { page: "/chatbot", label: "Chatbot" },
]

// IconButtonを利用すると、基本メニューのメニューバーがかなり横に広く表示されたので、IconButtonを利用しない方向で回避。
// IconButton相当の見た目にするためアルファチャンネル 0.54 を指定。
export const VisualizedMenu = (props: any) => {
  const { targetPage, menuLabel, currentPath } = props;
  const { isDesktop, isMobile } = useCustomMediaQuery();

  const DEFAULT_COLOR = "rgba(0, 0, 0, 0.5)";

  // マウスホバー時のスタイル
  const linkHoverStyle = {
    backgroundColor: '#226DAE',
    opacity: '0.8',
    color: 'white',
  };
  // デフォルト時のスタイル
  const linkDefaultStyle = {
    backgroundColor: 'transparent',
    opacity: '1.0',
    color: DEFAULT_COLOR,
  };
  // デフォルト時のスタイル(モバイル)
  const linkDefaultMobilStyle = {
    backgroundColor: '#d9d9d9',
    opacity: '1.0',
    color: 'white',
  };

  const sanitizedCurrentPath = currentPath.replace("DataRef", "");

  //現在開いている画面はlinkHoverStyleを適用する
  const leaveDesktopStyle = sanitizedCurrentPath === targetPage ? linkHoverStyle : linkDefaultStyle;
  const leaveMobileStyle = sanitizedCurrentPath === targetPage ? linkHoverStyle : linkDefaultMobilStyle;

  const DesktopMenu = styled(Link)(() => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: "5px 0",
    transition: 'color 0.3s',
    color: DEFAULT_COLOR,
    ...(sanitizedCurrentPath === targetPage ? linkHoverStyle : {})
  }));

  const MobileMenu = styled(Link)(() => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 0',
    margin: '0 2px',
    fontSize: '.8rem',
    transition: 'color 0.3s',
    ...(sanitizedCurrentPath === targetPage ? linkHoverStyle : linkDefaultMobilStyle)
  }));

  return (
    <>
      {isDesktop && (
        <DesktopMenu
          to={targetPage}
          onMouseEnter={(event) => { Object.assign(event.currentTarget.style, linkHoverStyle); }}
          onMouseLeave={(event) => { Object.assign(event.currentTarget.style, leaveDesktopStyle); }}>
          {props.children}
          <div style={{ marginLeft: "0.2em" }}>{menuLabel}</div>
        </DesktopMenu>
      )}
      {isMobile && (
        <MobileMenu
          to={targetPage}
          onMouseEnter={(event) => { Object.assign(event.currentTarget.style, linkHoverStyle); }}
          onMouseLeave={(event) => { Object.assign(event.currentTarget.style, leaveMobileStyle); }}>
          {props.children}
          <div style={{ textAlign: "center" }}>{menuLabel}</div>
        </MobileMenu>
      )}
    </>
  );
};

export const VisualizedMenus = (props: any) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  const Menubar = styled(Box)(() => ({
    position: 'fixed',
    top: '64px',
    left: '0',
    width: '100%',
    backgroundColor: 'white',
    zIndex: '100',
  }));

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  return (
    <>
      <Menubar>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <VisualizedMenu targetPage={PageUrl[0].page} menuLabel={PageUrl[0].label} currentPath={currentPath}>
            <HistoryEduOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[1].page} menuLabel={PageUrl[1].label} currentPath={currentPath}>
            <SchoolOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[2].page} menuLabel={PageUrl[2].label} currentPath={currentPath}>
            <BadgeOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[3].page} menuLabel={PageUrl[3].label} currentPath={currentPath}>
            <TimelineOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[4].page} menuLabel={PageUrl[4].label} currentPath={currentPath}>
            <BarChartOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[5].page} menuLabel={PageUrl[5].label} currentPath={currentPath}>
            <EditNoteOutlinedIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[6].page} menuLabel={PageUrl[6].label} currentPath={currentPath}>
            <SummarizeIcon />
          </VisualizedMenu>
          <VisualizedMenu targetPage={PageUrl[7].page} menuLabel={PageUrl[7].label} currentPath={currentPath}>
            <SentimentSatisfiedOutlinedIcon />
          </VisualizedMenu>
        </Box >
      </Menubar>
    </>
  );
};
