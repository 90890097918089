import {
  Box,
} from "@mui/material";
import { BasicTextAtom } from "./BasicTextAtom";

export const InputLabelAtom = (props: any) => {
  const { widthValue, marginLeftValue } = props;
  return (
    <Box sx={{ width: widthValue ?? "150px", marginLeft: marginLeftValue ?? "0" }}>
      <BasicTextAtom>
        {props.children}
      </BasicTextAtom>
    </Box>
  );
};

