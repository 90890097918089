import { AppBar, ButtonBase, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FC, useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Store } from "../store";
import types from "../store/types";

const Appbar: FC = () => {
  const { state, dispatch } = useContext(Store);
  const navigate = useNavigate();

  // 今日の日付を「年.月.日 (曜日英表記)」で取得する
  const getTodayText = (): string => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const weekday = days[date.getDay()];
    return `${year}.${month}.${day} (${weekday})`;
  };

  return (
    <>
      <AppBar position="sticky" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            className="test"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              dispatch({ type: types.SIDEBAR_CLICK });
            }}
          >
            <MenuIcon />
          </IconButton>
          <ButtonBase>
            <Typography variant="h6"
              onClick={() => { navigate("/home"); }}
            >
              鳴門教育大学　セルデザ　Self-Design Learning
            </Typography>
          </ButtonBase>
          <SpaceDiv></SpaceDiv>
          {state.userName !== undefined ? (
            // <Typography variant="h6">{state.userName}</Typography>
            <Typography variant="h6">{getTodayText()}</Typography>
          ) : (
            <></>
          )
          }
        </Toolbar>
      </AppBar>
    </>
  );
};

const SpaceDiv = styled.div`
    flex-grow: 1;
`;

export default Appbar;
