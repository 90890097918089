import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
} from "@mui/material";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Text, ReferenceArea, } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
/** @カスタムDOT 
import FavoriteIcon from '@mui/icons-material/Favorite'; //ハート
import AutoStoriesIcon from '@mui/icons-material/AutoStories'; //知識
import SettingsIcon from '@mui/icons-material/Settings'; //技能
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';//指導
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';//丸
*/

import {
  NCBTRequestType,
  NCBTResponseType,
} from "../common/type";
import {
  useAuth0Token,
  getNCBTScore,
} from "../common/http-requests";

import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  LineChartStyle: {
    '& > svg': {
      overflow: 'visible',
    },
    '& > svg.recharts-surface': {
      paddingBottom: '40px',
    }
  },
  LegendStyle: {
    padding: "0px",
    margin: "0px",
    textAlign: "center",
    '& > li': {
      display: "inline-block",
      marginRight: "10px;",
    },
    '& > li > span': {
      verticalAlign: "middle",
    }
  }
});

// 鳴門教育大学学章のメインカラーである「Utopia Blue」(#226DAE)を基準に指定しました。
const LINE_CHART_COLORS: string[] = [
  "#92DDFE", "#529DDE", "#226DAE", "#023D7E",
];

const LINE_CHART_DASHARRAY: string[] = [
  "4", "5 5", "1 1", "7 3 7",
];

const LINE_CHART_SIZE: number[] = [
  1200, 600
];

// backend API から取得されるデータ値のキーリスト
const LINE_DATA_KEYS = ['ps_ncbt_1', 'ps_ncbt_2', 'ps_ncbt_3', 'ps_ncbt_4'];
// グラフでの日本語系列名
const SERIES_NAMES_JP = ['構え', '知識', '技能', '実践的指導力'];

const LineChartStyle = {
  width: '100%',
  height: 'auto',
  maxWidth: `${LINE_CHART_SIZE[0]}px`,
  maxHeight: `${LINE_CHART_SIZE[1] + 50}px`,
  margin: 'auto',
}

const NCBTPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();

  // データ例
  // scores: [
  //   { 'exam_date': '2020/03/24', 'ps_ncbt_1': -0.5, 'ps_ncbt_2': -0.3, 'ps_ncbt_3': -0.7, 'ps_ncbt_4': -0.4 },
  //   { 'exam_date': '2021/04/24', 'ps_ncbt_1': 0.1, 'ps_ncbt_2': 0.4, 'ps_ncbt_3': 0.0, 'ps_ncbt_4': -0.1 },
  //   { 'exam_date': '2022/05/22', 'ps_ncbt_1': 1.0, 'ps_ncbt_2': 0.7, 'ps_ncbt_3': -0.1, 'ps_ncbt_4': 0.8 },
  //   { 'exam_date': '2023/05/24', 'ps_ncbt_1': 0.7, 'ps_ncbt_2': 0.0, 'ps_ncbt_3': 0.8, 'ps_ncbt_4': 0.5 }
  const [NCBTScoreData, setNCBTScoreData] = useState<NCBTResponseType>({
    scores: [],
  });

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getNCBTScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getNCBTScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: NCBTRequestType = {
        login_user_id,
      };
      const data: NCBTResponseType = await getNCBTScore(
        await getToken(),
        requestParams
      );
      setNCBTScoreData(data);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const CustomXAxisTick = ({ x, y, payload }: { x: number, y: number, payload: any }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <Text x={0} y={5} width={12} textAnchor="middle" verticalAnchor="start" fill="#666" fontSize="1.2rem">
          {payload.value}
        </Text>
      </g>
    );
  };

  /**  @カスタムDOT 
  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <ul className={classes.LegendStyle}>
        {
          payload.map((entry: string, index: number) => (
            <li key={`ncbt-${index}`}>
              {index === 0 ? (
                <FavoriteIcon style={{color: LINE_CHART_COLORS[index]}}/>
              ) : 
              index === 1 ? (
                <AutoStoriesIcon style={{color: LINE_CHART_COLORS[index]}}/>
              ) :
              index === 2 ? (
                <SettingsIcon style={{color: LINE_CHART_COLORS[index]}}/>
              ) :
              index === 3 ? (
                <SwitchAccessShortcutIcon style={{color: LINE_CHART_COLORS[index]}}/>
              ) : (
                <FiberManualRecordIcon style={{color: LINE_CHART_COLORS[index]}}/>
              )}
              <span>{entry}</span>
            </li>
          ))
        }
      </ul>
    );
  };

  const renderLineMarker = (props: any) => {
    const { cx, cy, dataKey, payload, key } = props;
    const keyset = `${dataKey}-${payload.categoryLabel}-${key}`;
    return (
      <>
        <svg key={keyset} x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
          {dataKey === '構え' ? (
            <FavoriteIcon style={{color: LINE_CHART_COLORS[0]}}/>
          ) : 
          dataKey === '知識' ? (
            <AutoStoriesIcon style={{color: LINE_CHART_COLORS[1]}} />
          ) :
          dataKey === '技能' ? (
            <SettingsIcon style={{color: LINE_CHART_COLORS[2]}}/>
          ) :
          dataKey === '実践的指導力' ? (
            <SwitchAccessShortcutIcon style={{color: LINE_CHART_COLORS[3]}} />
          ) : (
            <FiberManualRecordIcon style={{color: LINE_CHART_COLORS[0]}} />
          )}
        </svg>
      </>
    );
  };
  */

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            <Paper className={classes.chartContainer}>
              <LineChart
                width={LINE_CHART_SIZE[0]}
                height={LINE_CHART_SIZE[1]}
                data={NCBTScoreData.scores}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                className={classes.LineChartStyle}
                style={LineChartStyle}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="exam_date" tick={<CustomXAxisTick x={0} y={0} payload={NCBTScoreData.scores} />} />
                <YAxis domain={[-4.0, 4.0]} />
                <Tooltip />
                <ReferenceArea
                  x1={NCBTScoreData.scores[0].name}
                  x2={NCBTScoreData.scores[NCBTScoreData.scores.length - 1].name}
                  y1="1"
                  y2="-1"
                  strokeOpacity={0.3}
                  label="標準範囲"
                />
                <Legend
                  wrapperStyle={{ width: "100%", bottom: "5px", fontSize: "max(1.2vw, .8rem)" }}
                /** @カスタムDOT 
                 payload={NCBTScoreData.seriesNameList} 
                 content={renderLegend}
                */
                />
                {
                  LINE_DATA_KEYS.map((labelName, index) => (
                    <Line
                      key={labelName}
                      type="linear"
                      dataKey={labelName}
                      name={SERIES_NAMES_JP[index]}
                      stroke={LINE_CHART_COLORS[index % LINE_CHART_COLORS.length]}
                      activeDot={{ r: 8 }}
                      strokeDasharray={LINE_CHART_DASHARRAY[index % LINE_CHART_COLORS.length]}
                      strokeWidth={2}
                    /** @カスタムDOT 
                    dot={renderLineMarker} 
                    */
                    />
                  ))
                }
              </LineChart>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default NCBTPage;
