import types, { State, Actions } from "./types";

export default function reducer(state: State, action: Actions): State {
  switch (action.type) {
    case types.SIDEBAR_CLICK:
      return {
        ...state,
        sidebarState: !state.sidebarState,
      };
    case types.SET_USER_INFO:
      return {
        ...state,
        userId: action.payload.userId,
        userEmail: action.payload.userEmail,
        userName: action.payload.userName,
        userType: action.payload.userType,
        userStudentNumber: action.payload.userStudentNumber,
      };
    default:
      throw new Error("Unexpected Action Type");
  }
}
