import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
} from "@mui/material";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
// import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'; @AIからの分析は対応不要。

import {
  gpaRequestType,
  gpaResponseType,
} from "../common/type";
import {
  useAuth0Token,
  getGpaScore,
} from "../common/http-requests";

import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  LineChartStyle: {
    '& > svg.recharts-surface': {
      paddingBottom: '40px',
    }
  }
});

// 自分のラインは、鳴門教育大学学章のメインカラーである「Utopia Blue」を指定。
// 全学生ののラインは、灰色を指定。
const LINE_CHART_COLORS: string[] = [
  "#226DAE", "#808080",
];

const LINE_CHART_SIZE: number[] = [
  1200, 600
];

const LineChartStyle = {
  width: '100%',
  height: 'auto',
  maxWidth: `${LINE_CHART_SIZE[0]}px`,
  maxHeight: `${LINE_CHART_SIZE[1] + 50}px`,
  margin: 'auto',
}

/** @AIからの分析は対応不要。
const StyledPaperStyle = {
  offsetY: 22,
  offsetX: -10,
  bgColor: "#226DAE",
}
  */

const GPAPage: FC = () => {

  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  //Backendから送信されたgpaAverageDataデータ
  // 例）グラフに表示するデータ
  // const gpaScoreData.scoreList = [
  //       {"name": "2000(1年次)前期", "自分": 1.0, "全学生平均": 2.5},
  //       {"name": "2000(1年次)後期", "自分": 1.1, "全学生平均": 2.4},
  //       {"name": "2001(2年次)前期", "自分": 1.2, "全学生平均": 2.3},
  //       {"name": "2001(2年次)後期", "自分": 1.3, "全学生平均": 2.2},
  //       {"name": "2002(2年次)前期", "自分": 1.4, "全学生平均": 2.1},
  //       {"name": "2002(2年次)後期", "自分": 1.5, "全学生平均": 2.0},
  // ];
  // 例）グラフの表示に対する視覚情報
  // const seriesNameList = ["自分", "全学生平均"];
  const [gpaScoreData, setGpaScoreData] = useState<gpaResponseType>({
    scoreList: [],
    seriesNameList: [],
  });

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getGpaScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getGpaScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: gpaRequestType = {
        login_user_id,
      };
      const data: gpaResponseType = await getGpaScore(
        await getToken(),
        requestParams
      );
      setGpaScoreData(data);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  /** @AIからの分析は対応不要。
  const AIComment = () => {
    const isComment = "〇〇（data・能力）が前回ログイン時から変動（向上）しています。";
    const isCurrentDate = new Date();
    const fromDate = `${isCurrentDate.getFullYear()}年${isCurrentDate.getMonth() + 1}月${isCurrentDate.getDate()}日`;
    const fromTime = `${isCurrentDate.getHours()}:${isCurrentDate.getMinutes()}:${isCurrentDate.getSeconds()}`;
    return (
      <>
      <Box className="py-8 relative">
        <Box className="absolute inline-block top-12 left-0">
          <SentimentVerySatisfiedIcon />
        </Box>
        <Box className="inline-block ml-6">
          <StyledPaper>
            {isComment}
          </StyledPaper>
          <Box className="ml-16 text-gray-400">{fromDate} {fromTime}</Box>
        </Box>
      </Box>
      </>
    )
  };
  */

  const CustomXAxisTick = ({ x, y, payload }: { x: number, y: number, payload: any }) => {
    const name = payload.value;
    const nameSubstr1 = name.substr(0, name.indexOf('('));
    const nameSubstr2 = name.match(/\((.*)\)/)[0];
    const nameSubstr3 = name.substr(name.indexOf(')') + 1);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666" fontSize="1.2rem">
          {nameSubstr1}
        </text>
        <text x={0} y={0} dy={36} textAnchor="middle" fill="#666" fontSize="1.2rem">
          {nameSubstr2}
        </text>
        <text x={0} y={0} dy={58} textAnchor="middle" fill="#666" fontSize="1.2rem">
          {nameSubstr3}
        </text>
      </g>
    );
  };

  /** @グラフ上部に「自分」の平均値を表示するコンポーネント。
  const CustomTooltip  = () => {
    const isSelf = "自分";
    // const isStudent = "全学生平均";
    const isData = gpaScoreData.scoreList;
    let labelList: any = [];
    for(let i = 0; i < isData.length; i++){
      labelList.push(isData[i][isSelf]);
    }
    return (
      <Box className="text-center m-auto" sx={{ maxWidth: `calc(${LINE_CHART_SIZE[0]}px - 4vw)` }}>
        <Box className="flex justify-between">
        {labelList.map((value: string, index: number) => (
          <Box key={index} sx={{fontSize: "max(1.2vw, .8rem)", color: LINE_CHART_COLORS[0], marginLeft: "3vw"}}>{value}</Box>
        ))}
        </Box>
      </Box>
    )
  };
   */
  /** @AIからの分析は対応不要。
  const StyledPaper = styled(Paper)(({ theme }) => ({
    position: "relative",
    display: "inline-block",
    marginLeft: "20px",
    padding: theme.spacing(2),
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    backgroundColor: StyledPaperStyle.bgColor,
    color: 'white',
  "&::before": {
    content: '""',
    position: "absolute",
    borderTop: "10px solid transparent",
    borderRight: `10px solid ${StyledPaperStyle.bgColor}`,
    borderBottom: "10px solid transparent",
    top: StyledPaperStyle.offsetY,
    left: StyledPaperStyle.offsetX,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    borderTop: "10px solid transparent",
    borderRight: `10px solid ${StyledPaperStyle.bgColor}`,
    borderBottom: "10px solid transparent",
    top: StyledPaperStyle.offsetY,
    left: StyledPaperStyle.offsetX,
    filter: `drop-shadow(-2px 2px 4px rgba(0,0,0,0.2))
             drop-shadow(-4px 4px 5px rgba(0,0,0,0.14))
             drop-shadow(-1px 1px 10px rgba(0,0,0,0.12))`,
    zIndex: -1,
  },
  }));
    */

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              {/** <CustomTooltip /> @グラフ上部に「自分」の平均値を表示するコンポーネント。 */}
              <LineChart
                width={LINE_CHART_SIZE[0]}
                height={LINE_CHART_SIZE[1]}
                syncMethod={'value'}
                data={gpaScoreData.scoreList}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 15,
                }}
                className={classes.LineChartStyle}
                style={LineChartStyle}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="categoryLabel" tick={<CustomXAxisTick x={0} y={0} payload={gpaScoreData.scoreList} />} />
                <YAxis />
                <Tooltip />
                <Legend wrapperStyle={{ width: "100%", bottom: "5px", fontSize: "max(1.2vw, .8rem)" }} />
                {
                  gpaScoreData.seriesNameList.map((seriesName, index) => (
                    <Line
                      key={seriesName}
                      type="linear"
                      dataKey={seriesName}
                      stroke={LINE_CHART_COLORS[index % LINE_CHART_COLORS.length]}
                      activeDot={index === 0 ? { r: 8 } : undefined} // indexが0の場合のみactiveDotを設定
                      strokeWidth={1}
                    >
                      <LabelList
                        dataKey={seriesName}
                        position="insideTop"
                        fill={LINE_CHART_COLORS[index % LINE_CHART_COLORS.length]}
                        style={{ fontSize: isDesktop ? "1.4rem" : isMobile ? "2.8rem" : "1.2rem" }}
                      />
                    </Line>
                  ))
                }
              </LineChart>
            </Paper>
          </>
        )}
        {/**<AIComment /> @AIからの分析は対応不要。*/}
        <Toolbar />
      </Container>
    </>
  );
};

export default GPAPage;
