import * as actions from "./actions";

export default {
  SIDEBAR_CLICK: "SIDEBAR_CLICK",
  SET_USER_INFO: "SET_USER_INFO",
} as const;

export type State = {
  sidebarState: boolean;
  userId: number; // ログイン中のユーザID (userResponseType.id)
  userEmail: string; // ログイン中のユーザメールアドレス (userResponseType.email)
  userName: string; // ログイン中のユーザ名 (userResponseType.user_name)
  userType: number; // ログイン中のユーザ種類 (userResponseType.user_type)
  userStudentNumber: string; // ログイン中の学籍番号 (userResponseType.student_number)
};

type ReturnTypes<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any
    ? ReturnType<T[K]>
    : never;
};
type Unbox<T> = T extends { [K in keyof T]: infer U } ? U : never;
type CreatorsToActions<T> = Unbox<ReturnTypes<T>>;
export type Actions = CreatorsToActions<typeof actions>;
