import { Box } from "@mui/material";
import { InputLabelAtom } from "../atoms/InputLabelAtom";
import { PaddingAtom } from "../atoms/PaddingAtom";

export const InputFormMolecule = (props: any) => {
  const { inputLabel, vAlign, widthValue, marginLeftValue } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: vAlign || "center",
          padding: "0 0 0 0",
        }}
      >
        <InputLabelAtom widthValue={widthValue} marginLeftValue={marginLeftValue}>{inputLabel}</InputLabelAtom>
        {props.children}
      </Box>
      <PaddingAtom></PaddingAtom>
    </>
  );
};
