import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  Box,
  styled,
} from "@mui/material";

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { Toolbar } from "../components/FooterToolbar";
import { useCustomMediaQuery } from "../common/useCustomMediaQuery";
import SETSLineChart from '../components/SETSLineChart';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';//上矢印
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';//下矢印
import Groups3Icon from '@mui/icons-material/Groups3';//SEサーベイ
import SettingsAccessibilityIcon from '@mui/icons-material/SettingsAccessibility';//TSサーベイ

import {
  SETSRequestType,
  SETSResponseType,
} from "../common/type";
import {
  useAuth0Token,
  getSETSScore,
} from "../common/http-requests";

import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '10px 20px 10px',
    overflow: 'hidden'
  }
});

// 鳴門教育大学学章のメインカラーである「Utopia Blue」(#226DAE)を基準に指定しました。
const LINE_CHART_COLORS: string[] = [
  "#92DDFE", "#529DDE", "#226DAE", "#023D7E",
];

const LINE_CHART_SIZE: number[] = [
  800, 320
];

// backend API から取得されるデータ値のキーリスト
const SE_LINE_DATA_KEYS = ['ps_se_1', 'ps_se_2', 'ps_se_3', 'ps_se_4'];
const TS_LINE_DATA_KEYS = ['ps_ts_1', 'ps_ts_2', 'ps_ts_3', 'ps_ts_4'];
// グラフでの日本語系列名
const SE_SERIES_NAMES_JP = ['構え', '知識', '技能', '実践的指導力'];
const TS_SERIES_NAMES_JP = ['人間性', '連携・協働力', '課題発見・価値創造力', '省察力・職能成長志向'];

const AccordionLabel = styled(Box)(() => ({
  position: "relative",
  width: '100%',
  height: 'auto',
  maxWidth: `${LINE_CHART_SIZE[0]}px`,
  margin: 'auto',
  borderTop: `solid 1px ${LINE_CHART_COLORS[2]}`,
  borderBottom: `solid 1px ${LINE_CHART_COLORS[2]}`,
  color: `${LINE_CHART_COLORS[2]}`,
}));

const AccordionButton = styled(Box)(() => ({
  position: "absolute",
  display: "inline-block",
  top: "0",
  right: "0",
}));

const Accordion = styled(Box)(() => ({
  marginTop: "8px",
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-out',
}));

const SETSPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();
  const { isDesktop, isMobile } = useCustomMediaQuery();

  const [isSEOpen, setIsSEOpen] = useState(true);
  const [seheight, setSEHeight] = useState('0px');
  const [isTSOpen, setIsTSOpen] = useState(true);
  const [tsheight, setTSHeight] = useState('0px');

  // データ例
  // "se_scores": [
  //      {'response_date': '2024/07/22', 'ps_se_1': 5.1, 'ps_se_2': 5.6, 'ps_se_3': 5.7, 'ps_se_4': 5.6},
  //      {'response_date': '2025/10/22', 'ps_se_1': 5.8, 'ps_se_2': 5.9, 'ps_se_3': 5.5, 'ps_se_4': 5.8},
  //      {'response_date': '2026/10/22', 'ps_se_1': 5.0, 'ps_se_2': 4.7, 'ps_se_3': 4.6, 'ps_se_4': 5.1},
  //      {'response_date': '2027/11/22', 'ps_se_1': 6.8, 'ps_se_2': 6.5, 'ps_se_3': 7.0, 'ps_se_4': 7.0}
  // "ts_scores": [
  //      {'response_date': '2024/07/22', 'ps_ts_1': 6.1, 'ps_ts_2': 6.0, 'ps_ts_3': 6.0, 'ps_ts_4': 6.0},
  //      {'response_date': '2025/10/22', 'ps_ts_1': 5.7, 'ps_ts_2': 5.5, 'ps_ts_3': 5.0, 'ps_ts_4': 6.0},
  //      {'response_date': '2026/10/22', 'ps_ts_1': 5.2, 'ps_ts_2': 5.0, 'ps_ts_3': 5.0, 'ps_ts_4': 5.5},
  //      {'response_date': '2027/11/22', 'ps_ts_1': 7.0, 'ps_ts_2': 7.0, 'ps_ts_3': 7.0, 'ps_ts_4': 7.0}
  //    ]
  const [SETSScoreData, setSETSScoreData] = useState<SETSResponseType>({
    se_scores: [],
    ts_scores: [],
  });

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getSETScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getSETScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: SETSRequestType = {
        login_user_id,
      };
      const data: SETSResponseType = await getSETSScore(
        await getToken(),
        requestParams
      );
      setSETSScoreData(data);
    } catch (e) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    setSEHeight(isSEOpen ? `${LINE_CHART_SIZE[1]/*+40*/}px` : '0px');
  }, [isSEOpen]);

  useEffect(() => {
    setTSHeight(isTSOpen ? `${LINE_CHART_SIZE[1]}px` : '0px');
  }, [isTSOpen]);

  const handleSEAccordion = () => {
    setIsSEOpen(!isSEOpen);
  };

  const handleTSAccordion = () => {
    setIsTSOpen(!isTSOpen);
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <FeedbackBar
              feedbackInfo={feedbackInfo}
              handleClose={handleClose}
            ></FeedbackBar>
            <Paper className={classes.chartContainer}>
              <AccordionLabel>
                <Groups3Icon sx={{ margin: "0 10px 0 5px" }} />
                教職コンピテンシー
                <AccordionButton onClick={handleSEAccordion}>
                  {isSEOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </AccordionButton>
              </AccordionLabel>
              <Accordion sx={{ height: isDesktop && isSEOpen ? seheight : isMobile && isSEOpen ? "40vw" : seheight }}>
                <SETSLineChart
                  raderChartSize={LINE_CHART_SIZE}
                  scoreListData={SETSScoreData.se_scores}
                  lineDataKeys={SE_LINE_DATA_KEYS}
                  seriesNameListData={SE_SERIES_NAMES_JP}
                  raderChartColors={LINE_CHART_COLORS}
                  targetData={"SE"}
                />
              </Accordion>

              <AccordionLabel>
                <SettingsAccessibilityIcon sx={{ margin: "0 10px 0 5px" }} />
                トランスファラブルスキル
                <AccordionButton onClick={handleTSAccordion}>
                  {isTSOpen ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </AccordionButton>
              </AccordionLabel>
              <Accordion sx={{ height: isDesktop && isSEOpen ? tsheight : isMobile && isSEOpen ? "40vw" : tsheight }}>
                <SETSLineChart
                  raderChartSize={LINE_CHART_SIZE}
                  scoreListData={SETSScoreData.ts_scores}
                  lineDataKeys={TS_LINE_DATA_KEYS}
                  seriesNameListData={TS_SERIES_NAMES_JP}
                  raderChartColors={LINE_CHART_COLORS}
                  targetData={"TS"}
                />
              </Accordion>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default SETSPage;
