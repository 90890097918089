import {
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";

export const CircularProgressMolecule = (props: any) => {
  const { displayLabel } = props;
  return (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className="text-center">
            <CircularProgress
              style={{ margin: "10rem auto 0 auto", textAlign: "center" }}
              size="3rem"
            />
          </div>
          <Typography>{displayLabel}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
