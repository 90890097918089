import React, { createContext, useReducer } from "react";
import reducer from "./reducer";
import { Actions, State } from "./types";


type Props = {
  children: React.ReactNode;
};
type Dispatch = React.Dispatch<Actions>;

const initialState: State = {
  sidebarState: false,
  userId: 0,
  userEmail: "",
  userName: "",
  userType: -1,
  userStudentNumber: "",
};

export const Store = createContext<{ state: State; dispatch: Dispatch }>({
  state: initialState,
  dispatch: () => null,
});
const { Provider } = Store;

export default function StateProvider(props: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
}
