import React, { FC, useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { Toolbar } from "../components/FooterToolbar";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { getGpaScoreDataRef, useAuth0Token } from "../common/http-requests";
import { gpaRequestType, gpaDataRefResponseType } from "../common/type";
import { Store } from "../store";

// Material-UIのスタイル設定
const useStyles = makeStyles({
  chartContainer: {
    position: 'relative',
    marginTop: '60px',
    padding: '20px',
    overflow: 'hidden'
  },
  tableHeadStyle : {
    textAlign: "center",
    borderRight: "solid 1px #fff",
    lineHeight: "1rem",
    padding: "10px 2px",
  },
  tableCellStyle: {
    position: 'relative',
    padding: "0 2px",
    textAlign: 'center',
    overflow: "hidden",
  },
  tableCellRefStyle: {
    border: 'solid 1px #e2e2e2',
    boxSizing: 'border-box',
  },
  tableCellPsStyle: {
    border: 'solid 1px #bbcddd',
    boxSizing: 'border-box',
  },
  tableCellVertical: {
    writingMode: 'vertical-rl', 
    textOrientation: 'upright',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    width: '100%',
    height: '22%',
  },
});

const BAR_CHART_COLORS: string[] = [
  "#92DDFE","#529DDE","#226DAE","#023D7E","#a7a7a7",
];

// 初期データ構造
const DefaultGPADataRef: gpaDataRefResponseType = {
  data_ref: [],
};


const GPADataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);
  const classes = useStyles();

  const tableHeadListCS = [
    { label: "修得年", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "修得期", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "授業科目", width: "20vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "単位", width: "3vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
    { label: "得点", width: "4vw", color: "white", backgroundColor: BAR_CHART_COLORS[4] },
  ]

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };
  
  const [gpaDataRefData, setGpaDataRefData] = useState<gpaDataRefResponseType>(DefaultGPADataRef);

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getGpaScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getGpaScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: gpaRequestType = {
        login_user_id,
      };
      const data: gpaDataRefResponseType = await getGpaScoreDataRef(
        await getToken(),
        requestParams
      );
      setGpaDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  const earnedSemesterCodeFormat = (props: string) => {
    const semester = props === "1" ? "前期" : props === "2" ? "後期" : "-";
    return semester;
  };


  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            {feedbackInfo.open &&
              <FeedbackBar
                feedbackInfo={feedbackInfo}
                handleClose={handleClose}
              ></FeedbackBar>
            }
            <Paper className={classes.chartContainer}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead key="table-head">
                    <TableRow>
                      <TableCell 
                        className={classes.tableHeadStyle} 
                        sx={{ color: 'white', backgroundColor: BAR_CHART_COLORS[4] }}
                        colSpan={5}
                      >授業科目</TableCell>
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                      <TableCell 
                        className={classes.tableHeadStyle} 
                        sx={{ width: "10vw", color: 'white', backgroundColor: BAR_CHART_COLORS[1], fontSize: '.8rem' }}
                        rowSpan={2}
                        colSpan={1}
                      >Grade Point</TableCell>
                    </TableRow>
                    <TableRow>
                      {tableHeadListCS.map((item: any, index: number) => (
                        <TableCell 
                          key={`${item.label}-${index}`}
                          className={classes.tableHeadStyle} 
                          sx={{ width: item.width, color: item.color, backgroundColor: item.backgroundColor }}
                          rowSpan={3}
                        >{item.label}
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '2px', padding: '0' }}/>
                    </TableRow>
                  </TableHead>
                  <TableBody key="table-body">
                    {gpaDataRefData.data_ref.map((item: any, index: number) => (
                      <TableRow key={`table-body-row-${index}`}>
                        <React.Fragment key={`${item.subject_name}-${index}`}>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.earned_period}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{earnedSemesterCodeFormat(item.earned_semester_code)}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.subject_name}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit}</TableCell>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`}>{item.credit_score}</TableCell>
                          <TableCell sx={{ width: '2px', padding: '0' }}/>
                          <TableCell className={`${classes.tableCellStyle} ${classes.tableCellRefStyle}`} sx={{ color: BAR_CHART_COLORS[3] }}>{item.credit_grade_point}</TableCell>
                        </React.Fragment>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
        <Toolbar />
      </Container>
    </>
  );
};

export default GPADataRefPage;
