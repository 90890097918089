import { FC, useState, useEffect } from "react";
import {
  Container,
} from "@mui/material";
import homeImage from "./home.png"
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";

const HomePage: FC = () => {
  const [isSending, setIsSending] = useState(true);

  useEffect(() => {
    getPageData();
  }, []);

  const getPageData = async () => {
    setIsSending(true);
    try {
      ;
    } catch {
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            <div>
              <img src={homeImage} />
            </div>
          </>
        )}
      </Container >
    </>
  );
};

export default HomePage;
